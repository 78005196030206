// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  hostname: 'https://dev.b2b.diamant.info',
  stripe_pk: 'pk_test_psW41AXcnM1fV2rtduNMSRfl00KCFEgdyg',
  directus_url: 'https://directus.b2b.diamant.info/',
};
