import { isPlatformBrowser } from '@angular/common';
import * as i0 from "@angular/core";
export class LocalStorageService {
    constructor(platformId) {
        this.platformId = platformId;
    }
    store(key, value) {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(key, JSON.stringify(value));
        }
    }
    retrieve(key) {
        if (isPlatformBrowser(this.platformId)) {
            let item = localStorage.getItem(key);
            if (item) {
                if (typeof item === 'string' && item.startsWith('{') || item.startsWith('[') || item === 'false' || item === 'true' || item === 'null') { // TODO find a better solution
                    return JSON.parse(item);
                }
                else if (item !== 'undefined') {
                    return item;
                }
            }
            else {
                return undefined;
            }
        }
        return undefined;
    }
    clear(key) {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem(key);
        }
    }
}
LocalStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalStorageService_Factory() { return new LocalStorageService(i0.ɵɵinject(i0.PLATFORM_ID)); }, token: LocalStorageService, providedIn: "root" });
