/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./icon.component";
var styles_IconComponent = [i0.styles];
var RenderType_IconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IconComponent, data: {} });
export { RenderType_IconComponent as RenderType_IconComponent };
export function View_IconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "i", [["class", "icon"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "icon"; var currVal_1 = (_co.type + "-icon"); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_IconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icon", [], null, null, null, View_IconComponent_0, RenderType_IconComponent)), i1.ɵdid(1, 114688, null, 0, i3.IconComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IconComponentNgFactory = i1.ɵccf("app-icon", i3.IconComponent, View_IconComponent_Host_0, { type: "type" }, {}, ["*"]);
export { IconComponentNgFactory as IconComponentNgFactory };
