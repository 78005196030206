import { LocalStorageService } from '../../local-storage.service';
import { LoginService } from '../../user/login/login.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../../local-storage.service";
import * as i2 from "../../user/login/login.service";
import * as i3 from "@angular/common/http";
export class FavoritesService {
    constructor(storage, ls, http) {
        this.storage = storage;
        this.ls = ls;
        this.http = http;
        this.productIDs = [];
        this.cache = false;
        this.loadFavorites();
    }
    static getHttpParams(limit, offset) {
        let params = new HttpParams();
        if (limit !== undefined) {
            params = params.set('limit', String(limit));
        }
        if (offset !== undefined) {
            params = params.set('offset', String(offset));
        }
        return params;
    }
    /**
     * Adds a the product (as id) to the products array
     * @param productID
     */
    addProduct(productID) {
        if (productID) {
            if (!this.productIDs) {
                this.productIDs = [productID];
            }
            else if (!this.productIDs.includes(productID)) {
                this.productIDs.push(productID);
            }
            else {
                return;
            }
        }
    }
    getFavorites(limit, offset) {
        const headers = this.cache ? new HttpHeaders({ 'Content-Type': 'application/json' }) : new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        });
        const params = FavoritesService.getHttpParams(limit, offset);
        const options = { headers: headers, params: params };
        this.cache = true;
        return this.http.get('/api/favorites', options);
    }
    isFavorite(product) {
        if (!this.productIDs) {
            return false;
        }
        return this.productIDs.includes(product.articleNumber);
    }
    removeFromFavs(product) {
        this.removeProduct(product.articleNumber);
        this.push();
    }
    /**
     * Adds a new product to favs
     * @param product
     */
    addToFavs(product) {
        this.addProduct(product.articleNumber);
        this.push();
    }
    /**
     * Request API for user favs
     */
    loadFavorites() {
        this.ls.getUser().subscribe(user => {
            this.handleFavsLoading(user);
        });
    }
    removeProduct(productID) {
        if (this.productIDs) {
            const index = this.productIDs.indexOf(productID);
            if (index > -1) {
                this.productIDs.splice(index, 1);
            }
        }
    }
    /**
     * If user has online favs -> load it
     * Else load the local favs
     * @param user
     */
    handleFavsLoading(user) {
        if (user) {
            this.loadOnline(user.favs);
        }
        else {
            this.unauth();
        }
    }
    unauth() {
        this.cache = false;
        this.productIDs = [];
        this.storage.store('favs', this.productIDs);
        this.storage.store('online', false);
    }
    /**
     * Load favs from DB
     * @param onlineFavs
     */
    loadOnline(onlineFavs) {
        if (this.storage.retrieve('online')) {
            this.productIDs = onlineFavs;
        }
        else {
            this.productIDs = this.storage.retrieve('favs');
            //merge online and offline favs
            if (onlineFavs !== null) {
                for (let i = 0; i < onlineFavs.length; i++) {
                    this.addToFavs(onlineFavs[i]);
                }
            }
            this.storage.store('online', true);
        }
    }
    /**
     * Saves the favs to local storage and database
     */
    push() {
        this.cache = false;
        if (!this.productIDs) {
            this.productIDs = [];
        }
        this.storage.store('favs', this.productIDs);
        if (this.storage.retrieve('online')) {
            this.ls.updateFavorites({ favs: this.productIDs }).subscribe(success => true, err => this.unauth());
        }
        else {
            this.storage.store('online', false);
        }
    }
}
FavoritesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FavoritesService_Factory() { return new FavoritesService(i0.ɵɵinject(i1.LocalStorageService), i0.ɵɵinject(i2.LoginService), i0.ɵɵinject(i3.HttpClient)); }, token: FavoritesService, providedIn: "root" });
