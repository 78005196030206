import { Routes } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuard } from './user/login/auth.guard';
import { Page } from '../models/landingPageTile';
import { StorelocatorComponent } from './storelocator/storelocator.component';
const ɵ0 = { tiles: Page.danceshoes }, ɵ1 = { tiles: Page.danceshoes }, ɵ2 = () => import("./user/user.module.ngfactory").then(m => m.UserModuleNgFactory), ɵ3 = () => import("./cart/cart.module.ngfactory").then(m => m.CartModuleNgFactory), ɵ4 = () => import("./search/search.module.ngfactory").then(m => m.SearchModuleNgFactory), ɵ5 = () => import("./user/user.module.ngfactory").then(m => m.UserModuleNgFactory), ɵ6 = () => import("./blog/blog.module.ngfactory").then(m => m.BlogModuleNgFactory), ɵ7 = () => import("./blog/blog.module.ngfactory").then(m => m.BlogModuleNgFactory), ɵ8 = () => import("./dynamic-pages/dynamic-pages.module.ngfactory").then(m => m.DynamicPagesModuleNgFactory), ɵ9 = () => import("./dynamic-pages/dynamic-pages.module.ngfactory").then(m => m.DynamicPagesModuleNgFactory);
const routes = [
    {
        path: '', children: [
            // GERMAN
            // basic
            { path: '', component: LandingComponent },
            // ENGLISH
            // basic
            { path: 'en', component: LandingComponent },
            { path: 'storelocator', component: StorelocatorComponent },
            { path: 'en/storelocator', component: StorelocatorComponent },
            { path: 'tanzschuhe', component: LandingComponent, data: ɵ0 },
            { path: 'en/dance-shoes', component: LandingComponent, data: ɵ1 },
            // User
            { path: '', loadChildren: ɵ2 },
            // Cart
            { path: '', canActivate: [AuthGuard], loadChildren: ɵ3 },
            // Categories and Products
            { path: '', loadChildren: ɵ4 },
            { path: '', loadChildren: ɵ5 },
            { path: '404', component: NotFoundComponent },
            { path: 'blog', loadChildren: ɵ6 },
            { path: 'en/blog', loadChildren: ɵ7 },
            {
                path: '',
                loadChildren: ɵ8
            },
            {
                path: 'en',
                loadChildren: ɵ9
            },
            // escape
            { path: '**', component: NotFoundComponent }
        ]
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
