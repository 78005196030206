import DirectusSDK from '@directus/sdk-js';
import { environment } from '../environments/environment';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { LanguageService } from './language.service';
import { Page } from '../models/landingPageTile';
import { DomSanitizer } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "./language.service";
import * as i2 from "@angular/platform-browser";
export class DirectusService {
    constructor(ls, sanitizer) {
        this.ls = ls;
        this.sanitizer = sanitizer;
        this.client = new DirectusSDK({
            url: environment.directus_url,
            project: 'diamant',
        });
    }
    loadContent(collection, filter = {}, sort = null) {
        const params = { fields: '*.*', filter: filter, sort: sort };
        if (!params.filter) {
            delete params.filter;
        }
        if (!params.sort) {
            delete params.sort;
        }
        return from(this.client.getItems(collection, params))
            .pipe(
        // unnest data
        map((a) => a.data), 
        // unnest translations
        map((a) => {
            return a.map(b => {
                const transTmp = b.translations;
                for (const t of transTmp) {
                    b.translations[t.language] = t;
                }
                return b;
            });
        }), 
        // unnest selected language
        map((a) => {
            return a.map(b => {
                let attributes = b.translations[this.ls.l];
                if (!attributes) {
                    attributes = b.translations['de'];
                }
                delete attributes.id;
                for (const k of Object.keys(attributes)) {
                    b[k] = attributes[k];
                }
                b.url = b.url === '' ? null : b.url;
                b.content = !b.content || b.content === '' ? null : this.sanitizer.bypassSecurityTrustHtml(b.content);
                b.image = b.image ? b.image.data.full_url : null;
                delete b.translations;
                delete b.language;
                return b;
            });
        }));
    }
    loadLandingPageTiles(page = Page.b2b) {
        return this.loadContent('landing_page_tile', { 'page': { eq: page } });
    }
    loadBlogPosts() {
        return this.loadContent('blog_post', null, '-publication_date');
    }
    loadBlogPost(slug) {
        return this.loadContent('blog_post', { 'translations.slug': { eq: slug } })
            .pipe(map(p => p[0]));
    }
    loadDynamicPages() {
        return this.loadContent('info_page');
    }
    loadFooterHierarchy() {
        return this.loadDynamicPages()
            .pipe(map(res => {
            const categories = {
                company: [],
                product_info: [],
                dance_shoes: [],
            };
            for (const c of Object.keys(categories)) {
                categories[c] = res.filter(r => r.footer_category === c);
            }
            return categories;
        }));
    }
    loadDynamicPageHierarchy() {
        return this.loadDynamicPages()
            .pipe(map(res => {
            res.forEach(p => {
                if (p.parent) {
                    const parent = res.find(pb => pb.category_name === p.parent.category_name);
                    if (parent.children) {
                        parent.children.push(p);
                    }
                    else {
                        parent.children = [p];
                    }
                }
            });
            return res.filter(p => (!p.parent && !p.overview_hidden));
        }));
    }
    loadDynamicPage(slug) {
        return this.loadContent('info_page', { 'translations.slug': { eq: slug } })
            .pipe(map(p => p[0]));
    }
}
DirectusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DirectusService_Factory() { return new DirectusService(i0.ɵɵinject(i1.LanguageService), i0.ɵɵinject(i2.DomSanitizer)); }, token: DirectusService, providedIn: "root" });
