import * as i0 from "@angular/core";
/**
 * Size service to translate between different sizing systems
 */
export class SizeService {
    constructor() {
        // options: EU, UK, US
        this._format = 'UK';
        this.EU = {
            'H ': ['37 1/3', '38', '38 2/3', '39 1/3', '40', '40 2/3', '41 1/3', '42', '42 2/3', '43 1/3', '44', '44 2/3', '45 1/3', '46', '46 2/3', '47 1/3', '48', '48 2/3'],
            'H1': ['36', '36 2/3', '37 1/3', '38', '38 2/3', '39 1/3', '40', '40 2/3', '41 1/3', '42', '42 2/3', '43 1/3', '44', '44 2/3', '45 1/3', '46', '46 2/3', '47 1/3', '48', '48 2/3'],
            'D ': ['33 2/3', '34', '34 2/3', '35 1/3', '36', '36 2/3', '37 1/3', '38', '38 2/3', '39 1/3', '40', '40 2/3', '41 1/3', '42', '42 2/3', '43 1/3', '44'],
            'D5': ['34 2/3', '35 1/3', '36', '36 2/3', '37 1/3', '38', '38 2/3', '39 1/3', '40', '40 2/3', '41 1/3', '42', '42 2/3', '43 1/3', '44', '44 2/3', '45 1/3'],
            'K ': ['', '', '', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39'],
            'M ': ['', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39'],
            'SC': ['1', '2', '3']
        };
        this.UK = {
            'H ': ['4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13'],
            'H1': ['3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13'],
            'D ': ['1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5'],
            'D5': ['2.5', '3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5'],
            'K ': this.EU['K '],
            'M ': this.EU['M '],
            'SC': ['1', '2', '3']
        };
        this.US = {
            'H ': ['5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13', '13.5', '14'],
            'H1': ['4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13', '13.5', '14'],
            'D ': ['4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12'],
            'D5': ['5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13'],
            'K ': this.EU['K '],
            'M ': this.EU['M '],
            'SC': ['1', '2', '3']
        };
    }
    get format() {
        return this._format;
    }
    set format(format) {
        this._format = format;
    }
}
SizeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SizeService_Factory() { return new SizeService(); }, token: SizeService, providedIn: "root" });
