import {SafeHtml} from '@angular/platform-browser';

export interface LandingPageTile {
  id: number;
  image: string | null;
  columns: number;
  position: number;
  b2c: boolean;
  title: string | null;
  content: SafeHtml | null;
  url: string | null;
  image_tile: boolean;
  category_tile: boolean;
  call_to_action: string | null;
  page: Page;
}

export enum Page {
  b2b = 'b2b',
  b2c = 'b2c',
  danceshoes = 'danceshoes',
}
