/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/navbar/navbar.component.ngfactory";
import * as i3 from "./shared/navbar/navbar.component";
import * as i4 from "./shared/categories/categories.service";
import * as i5 from "./cart/cart.service";
import * as i6 from "./user/login/login.service";
import * as i7 from "./shared/navbar/overlay/overlay.service";
import * as i8 from "./language.service";
import * as i9 from "./shared/navbar/navbar.service";
import * as i10 from "@angular/router";
import * as i11 from "./shared/footer/footer.component.ngfactory";
import * as i12 from "./shared/footer/footer.component";
import * as i13 from "./directus.service";
import * as i14 from "./shared/toast/toast.component.ngfactory";
import * as i15 from "./shared/toast/toast.component";
import * as i16 from "./shared/toast/toast.service";
import * as i17 from "./app.component";
import * as i18 from "./analytics.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(1, 114688, null, 0, i3.NavbarComponent, [i4.CategoriesService, i5.CartService, i6.LoginService, i7.OverlayService, i8.LanguageService, i9.NavbarService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "main", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i10.RouterOutlet, [i10.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-footer", [], null, null, null, i11.View_FooterComponent_0, i11.RenderType_FooterComponent)), i1.ɵdid(6, 114688, null, 0, i12.FooterComponent, [i8.LanguageService, i13.DirectusService, i6.LoginService], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-toast", [], null, null, null, i14.View_ToastComponent_0, i14.RenderType_ToastComponent)), i1.ɵdid(8, 114688, null, 0, i15.ToastComponent, [i16.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); _ck(_v, 8, 0); }, function (_ck, _v) { var currVal_0 = "content"; _ck(_v, 2, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i17.AppComponent, [i10.Router, i18.AnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i17.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
