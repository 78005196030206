import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {LandingComponent} from './landing/landing.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {AgmCoreModule} from '@agm/core';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import {registerLocaleData} from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {UspSliderComponent} from './landing/usp-slider/usp-slider.component';
import {NgxJsonLdModule} from '@ngx-lite/json-ld';
import {TileContainerComponent} from './landing/tile-container/tile-container.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {Interceptor} from './interceptor';

registerLocaleData(localeDe, 'de');
registerLocaleData(localeEn, 'en');

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    NotFoundComponent,
    UspSliderComponent,
    TileContainerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    NgxJsonLdModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC2v3QaVlyI6m0cxJadwA7rrjhOHnujq8w',
      libraries: ['places'],
      language: 'de'
    }),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
