import {Component, NgZone, OnInit,} from '@angular/core';
import {StorelocatorService} from './storelocator.service';
import {MapsAPILoader} from '@agm/core';

declare let google: any;

@Component({
  selector: 'app-storelocator',
  templateUrl: './storelocator.component.html',
  styleUrls: ['./storelocator.component.scss']
})
export class StorelocatorComponent implements OnInit {

  stores: any[];
  lat: number;
  lng: number;
  compareLat: number;
  searched: boolean = false;
  currZoom: number = 2;
  savedAddress: string;

  constructor(private storelocatorService: StorelocatorService, private _loader: MapsAPILoader,
              private _zone: NgZone) {
  }

  ngOnInit() {
    if (localStorage.getItem('lat') !== null) {
      this.checkStorage();
    } else {
      this.askForLocation();
    }
    this.initAutocomplete();
  }

  askForLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => this.showPosition(position));
    }
  }

  checkStorage() {
    if (localStorage.getItem('lat') !== null) {
      this.lat = +localStorage.getItem('lat');
      this.lng = +localStorage.getItem('lng');
      this.savedAddress = localStorage.getItem('city');
      this.searched = true;
      this.findStores();
      this.currZoom = 9;
    }
  }

  showPosition(position) {
    this.lat = position.coords.latitude;
    this.lng = position.coords.longitude;
    this.searched = true;
    this.findStores();
    this.currZoom = 9;
  }

  initAutocomplete() {
    if (!this.isIE()) {

      this._loader.load().then(() => {
        const autocomplete = new google.maps.places.Autocomplete(
          document.getElementById('address'), {types: ['(cities)'] ['(postal_code)']});
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
          this._zone.run(() => {
            const place: any = autocomplete.getPlace();
            this.savedAddress = place.formatted_address;
            localStorage.setItem('city', this.savedAddress);
            this.lat = place.geometry.location.lat();
            this.lng = place.geometry.location.lng();
            this.findStores();
            this.currZoom = 9;
            this.searched = true;
          });
        });
      });
    }
  }

  findStores() {
    localStorage.setItem('lat', this.lat.toString());
    localStorage.setItem('lng', this.lng.toString());
    this.storelocatorService.findStores(this.lat, this.lng)
      .subscribe(res => this.stores = res);
  }

  public isIE() {
    const userAgent = navigator.userAgent;
    return userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1;
  }

  selectMarker(store) {
    this.compareLat = store.location.coordinates[1];
    this.scrollTo(store);
  }

  scrollTo(store): void {
    const elementList = document.querySelectorAll('[id="' + store.addressNumber + '"]');
    const element = elementList[0] as HTMLElement;
    element.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
  }
}
