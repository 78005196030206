export const text = {
  company: {
    de: 'Unternehmen',
    en: 'Company'
  },
  phone: {
    de: 'Kontakt per Telefon',
    en: 'Contact us via Phone'
  },
  email: {
    de: 'Kontakt per E-Mail',
    en: 'Contact us via email'
  },
  information: {
    de: 'Informationen',
    en: 'Information'
  },
  i18nVersion: {
    de: 'English Version',
    en: 'Deutsche Version'
  },
  danceShoes: {
    de: 'Tanzschuhe',
    en: 'Dance Shoes'
  },
  faq: {
    de: 'Häufige Fragen',
    en: 'FAQ'
  },
  footWidth: {
    de: 'Fußweiten',
    en: 'Foot Widths'
  },
  lexiconOfDances: {
    de: 'Tanzlexikon',
    en: 'Lexicon of Dances'
  },
  rightOfWithdrawal: {
    de: 'Widerrufsrecht',
    en: 'Right of Withdrawal'
  },
  returns: {
    de: 'Rücksendungen',
    en: 'Returns'
  },
  privacy: {
    de: 'Datenschutz',
    en: 'Privacy'
  },
  shippingCosts: {
    de: 'Versandkosten',
    en: 'Shipping Costs'
  },
  termsConditions: {
    de: 'AGB',
    en: 'Terms & Conditions'
  },
  imprint: {
    de: 'Impressum',
    en: 'Imprint'
  },
  disclaimerA: {
    de: 'Alle Preise exkl. gesetzl. Mehrwertsteuer zzgl. ',
    en: 'All prices are quoted without included value-added tax and without '
  },
  disclaimerLink: {
    de: 'Versandkosten',
    en: 'shipping costs'
  },
  disclaimerB: {
    de: ', wenn nicht anders beschrieben.',
    en: ', if not otherwise described.'
  },
  disclaimerC: {
    de: 'Copyright © ' + new Date().getFullYear() + ' Diamant Schuhfabrik Otto Müller KG - Alle Rechte vorbehalten.',
    en: 'Copyright © ' + new Date().getFullYear() + ' Diamant Schuhfabrik Otto Müller KG - All rights reserved'
  },
  paymentOptions: {
    de: 'Zahlungsoptionen',
    en: 'Payment options'
  },
  shippingServices: {
    de: 'Versandanbieter',
    en: 'Shipping services'
  },
  service: {
    de: 'Unser Service',
    en: 'Our service'
  },
  returnPolicy: {
    de: '60 Tage Rückgaberecht',
    en: '60-Day Return Policy'
  },
  madeInGermany: {
    de: 'Versand binnen 24h',
    en: 'Shipping within 24h'
  },
  freeReturns: {
    de: 'Kostenloser Versand (DE)',
    en: 'Free shipping (DE)'
  },
  pia: {
    de: 'VORKASSE',
    en: 'PIA'
  },
  productInfo: {
    de: 'Produktinformationen',
    en: 'Product Information'
  },
  buyDanceShoes: {
    de: 'Tanzschuhe kaufen',
    en: 'Buy Dance Shoes'
  }
};

export const contact = {
  tel: '+49619659450',
  telDisplay: '+49-(0)6196-5945-0',
  mail: 'info@diamant.net'
};

export const routes = {
  i18nVersion: {
    de: 'en',
    en: ''
  },
  // Missbrauch dieses Services >:D
  i18nLang: {
    de: 'en',
    en: 'de'
  },
  danceShoes: {
    de: 'tanzschuhe',
    en: 'en/dance-shoes'
  },
  faq: {
    de: 'haeufige-fragen',
    en: 'faq'
  },
  footWidth: {
    de: 'fussweiten',
    en: 'foot-widths'
  },
  lexiconOfDances: {
    de: 'tanzlexikon',
    en: 'lexicon-of-dances'
  },
  rightOfWithdrawl: {
    de: 'widerrufsrecht',
    en: 'right-of-withdrawal'
  },
  returns: {
    de: 'ruecksendungen',
    en: 'returns'
  },
  privacy: {
    de: 'datenschutz',
    en: 'privacy'
  },
  shippingCosts: {
    de: 'versandkosten',
    en: 'shipping-costs'
  },
  termsConditions: {
    de: 'agb',
    en: 'terms-and-conditions'
  },
  imprint: {
    de: 'impressum',
    en: 'imprint'
  },
  infoPage: {
    de: '',
    en: 'en'
  },
  infoOverview: {
    de: 'info',
    en: 'en/info'
  },
  blog: {
    de: 'blog',
    en: 'en/blog'
  },
  companyInfo: {
    de: 'diamant-schuhfabrik',
    en: 'en/diamant-shoe-factory',
  }
};
