/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/common";
import * as i5 from "./loading-bar.component";
import * as i6 from "./loading-bar.service";
var styles_LoadingBarComponent = [i0.styles];
var RenderType_LoadingBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingBarComponent, data: {} });
export { RenderType_LoadingBarComponent as RenderType_LoadingBarComponent };
function View_LoadingBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loading-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ngb-progressbar", [], null, null, null, i2.View_NgbProgressbar_0, i2.RenderType_NgbProgressbar)), i1.ɵdid(2, 49152, null, 0, i3.NgbProgressbar, [i3.NgbProgressbarConfig], { animated: [0, "animated"], striped: [1, "striped"], value: [2, "value"], height: [3, "height"] }, null)], function (_ck, _v) { var currVal_0 = true; var currVal_1 = true; var currVal_2 = 100; var currVal_3 = "8px"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_LoadingBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadingBarComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lbs.loading; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoadingBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading-bar", [], null, null, null, View_LoadingBarComponent_0, RenderType_LoadingBarComponent)), i1.ɵdid(1, 114688, null, 0, i5.LoadingBarComponent, [i6.LoadingBarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingBarComponentNgFactory = i1.ɵccf("app-loading-bar", i5.LoadingBarComponent, View_LoadingBarComponent_Host_0, {}, {}, []);
export { LoadingBarComponentNgFactory as LoadingBarComponentNgFactory };
