import * as tslib_1 from "tslib";
import { Collection } from '../../models/collection';
import { LoginService } from '../user/login/login.service';
import { Product } from '../../models/product';
import { LanguageService } from '../language.service';
import { LocalStorageService } from '../local-storage.service';
import { AnalyticsService } from '../analytics.service';
import { catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../local-storage.service";
import * as i2 from "../user/login/login.service";
import * as i3 from "../language.service";
import * as i4 from "../analytics.service";
import * as i5 from "@angular/common/http";
export class CartService {
    constructor(storage, ls, langS, as, http) {
        this.storage = storage;
        this.ls = ls;
        this.langS = langS;
        this.as = as;
        this.http = http;
        this._products = [];
        this._comment = '';
        this._customer_order_id = '';
        this._collections = [];
        // Debounce pushCart Function
        this.$pushCart = new Subject();
        this.$pushCart.asObservable().pipe(debounceTime(100)).subscribe(v => this._pushCart());
        // Debounce pushCollection
        this.$pushCollections = new Subject();
        this.$pushCollections.asObservable().pipe(debounceTime(100)).subscribe(v => this._pushCollections());
        // Subscribe Cart Loading to User changes
        ls.getUser().subscribe(user => this.handleCartLoading(user));
        // Initialize $deliveryDate
        this._currentDeliveryDate = new BehaviorSubject(storage.retrieve('deliverydate'));
        this.$deliveryDate = this._currentDeliveryDate.asObservable();
        this.remoteDeliveryDate();
    }
    /** Name for the Collection that is the cart */
    get CART_COLLECTION() {
        return 'cart';
    }
    get products() {
        return this._products;
    }
    /**
     * Adds passed products as Product-Objects to _products
     * and update Cart
     * @param products
     */
    set products(products) {
        this._products = CartService.buildProductArray(products);
        this.pushCart();
    }
    /** Return JSON presentation of Product[] eliminating underscores. */
    get productsAsJSON() {
        const productsJSON = [];
        for (const product of this._products) {
            productsJSON.push(product.toJSON());
        }
        return productsJSON;
    }
    get comment() {
        return this._comment;
    }
    /**
     * set _comment and update Cart
     * @param comment
     */
    set comment(comment) {
        this._comment = comment;
        this.pushCart();
    }
    get customer_order_id() {
        return this._customer_order_id;
    }
    /**
     * set _customer_order_id and update Cart
     * @param customer_order_id
     */
    set customer_order_id(customer_order_id) {
        this._customer_order_id = customer_order_id;
        this.pushCart();
    }
    get collections() {
        return this._collections;
    }
    /**
     * set _collections and update Collections
     * @param collections
     */
    set collections(collections) {
        this._collections = collections;
        this.pushCollections();
    }
    /** Return JSON presentation of Collection[] eliminating underscores. */
    get collectionsAsJSON() {
        const collectionsJSON = [];
        for (const collection of this._collections.filter(c => c.name)) {
            collectionsJSON.push(collection.toJSON());
        }
        return collectionsJSON;
    }
    get productCount() {
        return this.products.length;
    }
    /**
     * Converts Array of any to Pruducts via Product constructor
     * @param products
     */
    static buildProductArray(products) {
        const res = [];
        if (products !== null && products.length > 0) {
            for (let i = 0; i < products.length; i++) {
                const product = new Product(products[i]);
                if (product.amount !== undefined) {
                    res.push(new Product(product));
                }
            }
        }
        return res;
    }
    /**
     * sums two size arrays
     * @param a first array
     * @param b second array
     */
    static mergeAmounts(a, b) {
        let x = [];
        let y = [];
        const r = [];
        if (a.length > b.length) {
            x = a;
            y = b;
        }
        else {
            x = b;
            y = a;
        }
        for (let i = 0; i < x.length; i++) {
            if (x[i]) {
                if (y[i]) {
                    r[i] = x[i] + y[i];
                }
                else {
                    r[i] = x[i];
                }
            }
            else {
                if (y[i]) {
                    r[i] = y[i];
                }
            }
        }
        return r;
    }
    /**
     * Adds a new collection with products if provided, otherwise empty.
     * and update Collections
     * @param name of collection
     * @param products full array of collections's products
     */
    newCollection(name, products) {
        this.collections.push(new Collection(name));
        if (products !== undefined) {
            for (let i = 0; i < products.length; i++) {
                this.addTo(products[i], this.collections[this.collectionExists(name)].products);
            }
        }
        else {
            this.pushCollections();
        }
    }
    /**
     * Renames provided collection to provided name.
     * and updates collections
     * @param collection to rename
     * @param name new name of collection
     */
    renameCollection(collection, name) {
        collection.name = name;
        this.pushCollections();
    }
    /**
     * Removes collection at provided index.
     * and updates collections
     * @param collectionIndex index of collection
     */
    removeCollection(collectionIndex) {
        this.collections.splice(collectionIndex, 1);
        this.pushCollections();
    }
    /**
     * Move product from a collection to cart or vice versa.
     * and updates cart or collections or both
     * @param product to move
     * @param from array to (re)move product from (cart or collection)
     * @param to array to move/add product to (cart or collection)
     */
    moveProduct(product, from, to) {
        this.removeFrom(product, from, false); // this updates cart or collections based on from
        // Check if same product is already in collection and merge to one item if so.
        for (let i = 0; i < to.length; i++) {
            if (to[i].id === product.id) {
                to[i].size = CartService.mergeAmounts(to[i].size, product.size);
                return;
            }
        }
        to.unshift(product);
        // Update Cart or collection based on to
        if (this.isCart(to)) {
            this.pushCart();
        }
        else if (this.isCollection(to)) {
            this.pushCollections();
        }
    }
    /**
     * Merges collection at provided index with cart.
     * and updates cart, collections or both
     * @param collectionIndex index of collection to merge
     */
    mergeCollectionWithCart(collectionIndex) {
        while (this.collections[collectionIndex].products.length > 0) {
            this.moveProduct(this.collections[collectionIndex].products[0], this.collections[collectionIndex].products, this._products);
        }
    }
    /**
     * clear cart and collections
     */
    unauth() {
        this.storage.store('online', false);
        this.storage.store('cart', { products: [], comment: '', customer_order_id: '' });
        this.storage.store('collections', []);
        this._products = [];
        this._collections = [];
        this._comment = '';
        this._customer_order_id = '';
    }
    /**
     * Adds a new product to arr or increases the amount if it already exists.
     * and updates cart, collections or both
     * @param product to add
     * @param arr array to add product to (either cart or a collection)
     */
    addTo(product, arr) {
        const alreadyAdded = this.products[this.alreadyAdded(product, arr)];
        if (!product.hasSizes) {
            this.changeAmount(product, (alreadyAdded ? alreadyAdded.amount : 0) + product.amount, arr);
        }
        else {
            this.changeSizeAmount(product, product.size, arr);
        }
    }
    /**
     * Removes a product from the cart
     * @param product
     * @param arr array to remove product from (either cart or collection)
     * @param removeFromAS boolean which indicates if item should be removed from AnalyticsService or not
     */
    removeFrom(product, arr, removeFromAS) {
        if (this.alreadyAdded(product, arr) > -1) {
            if (removeFromAS) {
                this.as.remove(new Product(product));
            }
            arr.splice(this.alreadyAdded(product, arr), 1);
        }
        const isCart = this.isCart(arr);
        const isCollection = this.isCollection(arr);
        // Notify other tabs / windows.
        if (isCart) {
            this.pushCart();
        }
        if (isCollection) {
            this.pushCollections();
        }
    }
    /**
     * Changes the amount of a product, adds it, if it doesn't exist or removes it if the amount is lower than 1
     * @param product
     * @param size
     * @param arr array in which size/amount should be changed (either cart or a collection)
     */
    changeSizeAmount(product, size, arr) {
        product.size = size;
        if (this.alreadyAdded(product, arr) > -1) {
            if (product.amount < 1) { // TODO is this intended behavior for all collections?
                this.removeFrom(product, arr, true);
                return;
            }
            arr[this.alreadyAdded(product, arr)].size = size;
        }
        else {
            this._addTo(product, arr);
        }
        const isCart = this.isCart(arr);
        const isCollection = this.isCollection(arr);
        // Notify other tabs / windows.
        if (isCart) {
            this.pushCart();
        }
        if (isCollection) {
            this.pushCollections();
        }
    }
    /**
     * changes or adds product with amount to array arr or removes if lower than 1
     * and updates cart or collections
     * @param product
     * @param amount
     * @param arr
     */
    changeAmount(product, amount, arr) {
        if (this.alreadyAdded(product, arr) > -1) {
            if (amount < 1) { // TODO is this intended behavior for all collections?
                this.removeFrom(product, arr, true);
                return;
            }
            this.products[this.alreadyAdded(product, arr)].amount = amount;
        }
        else {
            product.amount = amount;
            this._addTo(product, arr);
        }
        const isCart = this.isCart(arr);
        const isCollection = this.isCollection(arr);
        // Notify other tabs / windows.
        if (isCart) {
            this.pushCart();
        }
        if (isCollection) {
            this.pushCollections();
        }
    }
    /**
     * Calculates the price of the passed (cart's or collection's) contents
     * @param products array to get the price for (either from cart or from collection)
     * @returns total price for passed products
     */
    calculatePriceFor(products) {
        let price = 0;
        for (let i = 0; i < products.length; i++) {
            price += products[i].totalPrice;
        }
        return price;
    }
    /**
     * Calculates the price of the passed (cart's or collection's) contents with no vat.
     * @param products array to get the no vat price for (either from cart or from collection)
     * @returns total price for passed products with no vat
     */
    calculateNoVatPriceFor(products) {
        return this.calculatePriceFor(products) / 1.19;
    }
    /**
     * Checks if product was already added to provided array [-1 = wasn't added; {number} = position of product]
     * @param product
     * @param arr array to be checked (either cart or collection)
     * @returns {number}
     */
    alreadyAdded(product, arr) {
        if (product && arr) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].id === product.id) {
                    return i;
                }
            }
        }
        return -1;
    }
    /**
     * Checks if collection with provided name already exists.
     * @param name of collection
     * @returns {number} [-1 = doesn't exist; {number} = index of collection]
     */
    collectionExists(name) {
        for (let i = 0; i < this.collections.length; i++) {
            if (this.collections[i].name === name) {
                return i;
            }
        }
        return -1;
    }
    /**
     * allowedCollectionName returns true if provided name can be used for a new collection
     * (hence is not empty and does not yet exist).
     * @param name collection name candidate
     */
    allowedCollectionName(name) {
        return this.collectionExists(name) === -1 && name !== '' && name !== undefined && name !== 'cart';
    }
    /**
     * Gets products of provided collection or cart contents if no collection is specified.
     * @param collectionName of collection, if null cart contents will be returned
     */
    getProducts(collectionName) {
        if (collectionName && collectionName !== this.CART_COLLECTION) {
            const index = this.collectionExists(collectionName);
            return index > -1 ? this.collections[index].products : this.products;
        }
        else {
            return this.products;
        }
    }
    isCart(arr) {
        return arr === this.products;
    }
    isCollection(arr) {
        for (let i = 0; i < this.collections.length; i++) {
            if (this.collections[i].products === arr) {
                return true;
            }
        }
        return false;
    }
    /**
     * Returns true if all products in collection or cart (provided by name) are buyable.
     * @param name of collection or cart
     */
    isCheckoutable(name) {
        for (const product of this.getProducts(name)) {
            if (!product.isBuyable) {
                return false;
            }
        }
        return true;
    }
    /**
     * Cast collections array back into Collection type after receiving it from api or local storage
     * (either with or without underscores).
     */
    buildCollections(collections) {
        const castedCollections = [];
        if (collections && collections.length > 0) {
            for (let i = 0; i < collections.length; i++) {
                castedCollections.push(new Collection(collections[i].name || collections[i]._name, collections[i].products || collections[i]._products)); // underscore syntax fallback is needed for old accounts that used wrong default data
            }
        }
        return castedCollections;
    }
    /**
     * imports a Collection and triggers refresh
     * @param file
     * @param name
     */
    importCollection(file, name) {
        return this.ls.importCollections(file, name).pipe(catchError(err => {
            return throwError(err);
        }));
    }
    getDeliveryDate() {
        return this.$deliveryDate.pipe(distinctUntilChanged());
    }
    pushCart() {
        this.$pushCart.next();
    }
    pushCollections() {
        this.$pushCollections.next();
    }
    remoteDeliveryDate() {
        this.http.get('/api/deliverydate').subscribe(date => {
            this._currentDeliveryDate.next(date);
            this.storage.store('deliverydate', date);
        });
    }
    /**
     * Prepends a the product (as object) to provided array
     * and notify google analytics
     * @param product
     * @param arr array to add product to (either cart or a collection)
     */
    _addTo(product, arr) {
        if (product.amount === undefined) {
            return false;
        }
        const p = new Product(product);
        arr.unshift(p);
        this.as.add(p);
    }
    /**
     * If user has online cart and collections -> load it
     * Else load the local cart and collections
     * @param user
     */
    handleCartLoading(user) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (user) {
                this.loadOnline(user.cart, this.buildCollections(user.collections));
            }
            else {
                this.unauth();
            }
        });
    }
    /**
     * Load the local cart and collections.
     */
    loadLocal() {
        if (!this.storage.retrieve('online')) {
            if (this.storage.retrieve('cart') !== null) {
                const cart = this.storage.retrieve('cart') || { products: [], comment: '', customer_order_id: '' };
                this._products = CartService.buildProductArray(cart.products || []);
                this._comment = cart.comment ? cart.comment : '';
                this._customer_order_id = cart.customer_order_id ? cart.customer_order_id : '';
            }
            if (this.storage.retrieve('collections') !== null) {
                this._collections = this.buildCollections(this.storage.retrieve('collections'));
            }
        }
    }
    /**
     * merge the db collections and cart with the cart
     * @param onlineProducts
     * @param collectionName
     */
    mergeOnlineOfflineCollection(onlineProducts, collectionName) {
        // TODO better merging tactic than adding
        if (Array.isArray(onlineProducts) && onlineProducts.length > 0) {
            const reversedCart = onlineProducts.slice().reverse();
            for (let i = 0; i < reversedCart.length; i++) {
                this.addTo(new Product(reversedCart[i]), this.getProducts(collectionName));
            }
        }
    }
    /**
     * Load cart and collections from DB.
     * @param onlineCart
     * @param onlineCollections
     */
    loadOnline(onlineCart, onlineCollections) {
        const isCartOnline = this.storage.retrieve('online');
        if (isCartOnline) {
            if (Array.isArray(onlineCart)) { //TODO check if this is ever the case I believe this is a backwards compatibility workaround
                this._products = CartService.buildProductArray(onlineCart);
            }
            else {
                this._products = CartService.buildProductArray(onlineCart.products);
                this._comment = onlineCart.comment;
                this._customer_order_id = onlineCart.customer_order_id;
            }
            this._collections = onlineCollections;
        }
        else { //TODO check if this is ever the case
            this.loadLocal();
            // merge online and offline cart
            if (Array.isArray(onlineCart) && onlineCart.length > 0) { //TODO check if this is ever the case I believe this is a backwards compatibility workaround
                this.mergeOnlineOfflineCollection(onlineCart);
            }
            else if (onlineCart) {
                this.mergeOnlineOfflineCollection(onlineCart.products);
                this._comment = onlineCart.comment || this._comment;
                this._customer_order_id = onlineCart.customer_order_id || this._customer_order_id;
            }
            // merge online and offline collections
            if (onlineCollections !== null) {
                for (let i = 0; i < onlineCollections.length; i++) {
                    const index = this.collectionExists(onlineCollections[i].name);
                    if (index === -1) {
                        const reversedColProducts = onlineCollections[i].products.slice().reverse();
                        this.newCollection(onlineCollections[i].name, reversedColProducts);
                    }
                    else {
                        this.mergeOnlineOfflineCollection(onlineCollections[i].products, onlineCollections[i].name);
                    }
                }
            }
            this.storage.store('online', true);
        }
    }
    _pushCart() {
        this.storage.store('cart', {
            products: this.products,
            comment: this.comment,
            customer_order_id: this.customer_order_id
        });
        if (this.storage.retrieve('online')) {
            this.http.put('/auth/cart', {
                cart: {
                    products: this.productsAsJSON,
                    comment: this.comment,
                    customer_order_id: this.customer_order_id
                }
            }).subscribe(success => true, err => this.unauth());
        }
        else {
            this.storage.store('online', false);
        }
    }
    _pushCollections() {
        this.storage.store('collections', this.collections);
        if (this.storage.retrieve('online')) {
            this.http.put('/auth/collections', { collections: this.collectionsAsJSON }).subscribe(success => true, err => this.unauth());
        }
        else {
            this.storage.store('online', false);
        }
    }
}
CartService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CartService_Factory() { return new CartService(i0.ɵɵinject(i1.LocalStorageService), i0.ɵɵinject(i2.LoginService), i0.ɵɵinject(i3.LanguageService), i0.ɵɵinject(i4.AnalyticsService), i0.ɵɵinject(i5.HttpClient)); }, token: CartService, providedIn: "root" });
