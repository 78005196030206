import {Injectable} from '@angular/core';
import DirectusSDK from '@directus/sdk-js';
import {environment} from '../environments/environment';
import {IConfiguration} from '@directus/sdk-js/dist/types/Configuration';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LanguageService} from './language.service';
import {LandingPageTile, Page} from '../models/landingPageTile';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class DirectusService {

  client = new DirectusSDK({
    url: environment.directus_url,
    project: 'diamant',
  } as IConfiguration);

  constructor(private ls: LanguageService, private sanitizer: DomSanitizer) {

  }

  private loadContent(collection: string, filter: any = {}, sort: string | string[] = null): Observable<any[]> {
    const params = {fields: '*.*', filter: filter, sort: sort};
    if (!params.filter) {
      delete params.filter;
    }
    if (!params.sort) {
      delete params.sort;
    }
    return from(this.client.getItems(collection, params))
      .pipe(
        // unnest data
        map((a: any) => a.data),
        // unnest translations
        map((a: any) => {
          return a.map(b => {
            const transTmp = b.translations;
            for (const t of transTmp) {
              b.translations[t.language] = t;
            }
            return b;
          });
        }),
        // unnest selected language
        map((a: any) => {
          return a.map(b => {
            let attributes = b.translations[this.ls.l];
            if (!attributes) {
              attributes = b.translations['de'];
            }
            delete attributes.id;
            for (const k of Object.keys(attributes)) {
              b[k] = attributes[k];
            }
            b.url = b.url === '' ? null : b.url;
            b.content = !b.content || b.content === '' ? null : this.sanitizer.bypassSecurityTrustHtml(b.content);
            b.image = b.image ? b.image.data.full_url : null;
            delete b.translations;
            delete b.language;
            return b as LandingPageTile;
          });
        })
      );
  }

  loadLandingPageTiles(page: Page = Page.b2b): Observable<LandingPageTile[]> {
    return this.loadContent('landing_page_tile', {'page': {eq: page}});
  }

  loadBlogPosts(): Observable<any[]> {
    return this.loadContent('blog_post', null, '-publication_date');
  }

  loadBlogPost(slug: string): Observable<any[]> {
    return this.loadContent('blog_post', {'translations.slug': {eq: slug}})
      .pipe(
        map(p => p[0])
      );
  }

  loadDynamicPages(): Observable<any[]> {
    return this.loadContent('info_page');
  }

  loadFooterHierarchy(): Observable<any> {
    return this.loadDynamicPages()
      .pipe(
        map(
          res => {
            const categories = {
              company: [],
              product_info: [],
              dance_shoes: [],
            };
            for (const c of Object.keys(categories)) {
              categories[c] = res.filter(r => r.footer_category === c);
            }
            return categories;
          }
        )
      );
  }

  loadDynamicPageHierarchy(): Observable<any[]> {
    return this.loadDynamicPages()
      .pipe(
        map(
          res => {
            res.forEach(p => {
              if (p.parent) {
                const parent = res.find(pb => pb.category_name === p.parent.category_name);
                if (parent.children) {
                  parent.children.push(p);
                } else {
                  parent.children = [p];
                }
              }
            });
            return res.filter(p => (!p.parent && !p.overview_hidden));
          }
        )
      );
  }

  loadDynamicPage(slug: string): Observable<any[]> {
    return this.loadContent('info_page', {'translations.slug': {eq: slug}})
      .pipe(
        map(p => p[0])
      );
  }
}
