import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

declare let localStorage: any;

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(@Inject(PLATFORM_ID) protected platformId: Object) {
  }

  public store(key: string, value: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  public retrieve(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      let item = localStorage.getItem(key);
      if (item) {
        if (typeof item === 'string' && item.startsWith('{') || item.startsWith('[') || item === 'false' || item === 'true' || item === 'null') { // TODO find a better solution
          return JSON.parse(item);
        } else if (item !== 'undefined') {
          return item;
        }
      } else {
        return undefined;
      }
    }
    return undefined;
  }

  public clear(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(key);
    }
  }

}
