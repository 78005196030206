/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./searchbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../product-typeahead/product-typeahead.component.ngfactory";
import * as i4 from "../../product-typeahead/product-typeahead.component";
import * as i5 from "../../categories/categories.service";
import * as i6 from "../../../search/search.service";
import * as i7 from "../navbar.service";
import * as i8 from "../../../language.service";
import * as i9 from "../../../search/favorites/favorites.service";
import * as i10 from "./searchbar.component";
import * as i11 from "@angular/router";
import * as i12 from "../overlay/overlay.service";
var styles_SearchbarComponent = [i0.styles];
var RenderType_SearchbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchbarComponent, data: {} });
export { RenderType_SearchbarComponent as RenderType_SearchbarComponent };
export function View_SearchbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "input-group search-bar"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "active": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-product-typeahead", [], null, [[null, "selectItem"], [null, "inputBlur"], [null, "inputFocus"], [null, "iconClick"], [null, "enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectItem" === en)) {
        var pd_0 = (_co.onSelect($event) !== false);
        ad = (pd_0 && ad);
    } if (("inputBlur" === en)) {
        var pd_1 = (_co.onBlur() !== false);
        ad = (pd_1 && ad);
    } if (("inputFocus" === en)) {
        var pd_2 = (_co.onFocus() !== false);
        ad = (pd_2 && ad);
    } if (("iconClick" === en)) {
        var pd_3 = (_co.onIcon($event) !== false);
        ad = (pd_3 && ad);
    } if (("enter" === en)) {
        var pd_4 = (_co.onEnter($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i3.View_ProductTypeaheadComponent_0, i3.RenderType_ProductTypeaheadComponent)), i1.ɵdid(5, 114688, null, 0, i4.ProductTypeaheadComponent, [i5.CategoriesService, i6.SearchService, i7.NavbarService, i8.LanguageService, i9.FavoritesService], { preselect: [0, "preselect"] }, { selectItem: "selectItem", inputBlur: "inputBlur", inputFocus: "inputFocus", iconClick: "iconClick", enter: "enter" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "input-group search-bar"; var currVal_1 = _ck(_v, 3, 0, _co.isFocused); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = false; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_SearchbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-searchbar", [], null, null, null, View_SearchbarComponent_0, RenderType_SearchbarComponent)), i1.ɵdid(1, 114688, null, 0, i10.SearchbarComponent, [i6.SearchService, i11.Router, i12.OverlayService, i8.LanguageService, i7.NavbarService, i5.CategoriesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchbarComponentNgFactory = i1.ɵccf("app-searchbar", i10.SearchbarComponent, View_SearchbarComponent_Host_0, {}, {}, []);
export { SearchbarComponentNgFactory as SearchbarComponentNgFactory };
