import {Injectable} from '@angular/core';
import {CategoriesService} from './shared/categories/categories.service';

declare let ga;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private catS: CategoriesService) {
  }

  addPageView(url: string) {
    if (!this.isPrerender()) {
      ga('set', 'page', url);
      ga('send', 'pageview');
    }
  }

  addImpression(product: any, list?: string) {
    if (!this.isPrerender()) {
      const impression: any = {
        id: product.articleNumber,
        name: product.name,
        price: product.totalPrice,
        category: this.catS.getCategoryHierarchy(product.categories).join('/')
      };
      if (list) {
        impression.list = list;
      }

      ga('ec:addImpression', impression);
    }
  }

  click(product: any, list?: string) {
    ga('ec:addProduct', this.gaAddProduct(product));
    ga('ec:setAction', 'click');
    ga('send', 'event', 'UX', 'click', 'Click product');
  }

  detail(product: any, list?: string) {
    if (!this.isPrerender()) {
      ga('ec:addProduct', this.gaAddProduct(product));
      ga('ec:setAction', 'detail');
      ga('send', 'event', 'Product Detail', 'viewed');
    }
  }

  add(product: any) {
    ga('ec:addProduct', this.gaAddProduct(product));
    ga('ec:setAction', 'add');
    ga('send', 'event', 'UX', 'click', 'Add to cart');
  }

  remove(product: any) {
    ga('ec:addProduct', this.gaAddProduct(product));
    ga('ec:setAction', 'remove');
    ga('send', 'event', 'UX', 'click', 'Remove from cart');
  }

  initiateCheckout(products: any) {
    products.map(p => {
      ga('ec:addProduct', this.gaAddProduct(p));
    });
    ga('ec:setAction', 'checkout', {
      step: 1,
      option: 'Login'
    });
    ga('send', 'event', 'Checkout', 'Initiate');
  }

  editAddress() {
    ga('ec:setAction', 'checkout', {'step': 2});
    ga('send', 'event', 'Checkout', 'Address');
  }

  selectPayment(paymentType) {
    ga('ec:setAction', 'checkout', {'step': 3, 'option': paymentType});
    ga('send', 'event', 'Checkout', 'Payment');
  }

  /*selectShipping(shippingType) {
    ga('ec:setAction', 'checkout', {'step': 3, 'option': shippingType});
    ga('send', 'event', 'Checkout', 'Shipment');
  }*/

  overview() {
    ga('ec:setAction', 'checkout', {'step': 4});
    ga('send', 'event', 'Checkout', 'Overview');
  }

  purchase(products: any, orderNum: any, revenue: number, shipping: number) {
    products.map(p => {
      ga('ec:addProduct', this.gaAddProduct(p));
    });
    ga('set', 'currencyCode', 'EUR');
    ga('ec:setAction', 'purchase', {
      id: orderNum,
      revenue: revenue,
      shipping: shipping,
      tax: 0
    });
    ga('send', 'event', 'UX', 'click', 'Order');
  }

  gaAddProduct(product: any) {
    return {
      id: product.articleNumber,
      name: product.name,
      price: product.totalPrice,
      category: this.catS.getCategoryHierarchy(product.categories).join('/'),
      quantity: product.amount ? product.amount : 1
    };
  }

  checkoutFailed() {
  }

  private isPrerender() {
    return (/Prerender/i.test(navigator.userAgent));
  }
}
