import {Component, OnInit} from '@angular/core';
import {CategoriesService} from './categories.service';
import {ActivatedRoute} from '@angular/router';
import {LanguageService} from '../../language.service';
import {OverlayService} from '../navbar/overlay/overlay.service';
import {LoginService} from '../../user/login/login.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public text = {
    new: {
      de: 'Neu',
      en: 'New'
    },
    sale: {
      de: 'Sale',
      en: 'Sale'
    },
    ladies: {
      de: 'Damen',
      en: 'Ladies'
    },
    men: {
      de: 'Herren',
      en: 'Men'
    },
    children: {
      de: 'Kinder',
      en: 'Children'
    },
    latin: {
      de: 'Latein',
      en: 'Latin'
    },
    standard: {
      de: 'Standard',
      en: 'Standard'
    },
    tango: {
      de: 'Salsa & Tango',
      en: 'Salsa & Tango'
    },
    trainer: {
      de: 'Trainer',
      en: 'Trainer'
    },
    wedding: {
      de: 'Hochzeit',
      en: 'Wedding'
    },
    bridal: {
      de: 'Brautschuhe',
      en: 'Bridal'
    },
    ballroom: {
      de: 'Sneaker',
      en: 'Sneaker'
    },
    boys: {
      de: 'Knaben',
      en: 'Boys'
    },
    girls: {
      de: 'Mädchen',
      en: 'Girls'
    },
    calendar: {
      de: 'Kalender',
      en: 'Calendar'
    },
    comfy: {
      de: 'Bequem',
      en: 'Comfy'
    },
    festive: {
      de: 'Festlich',
      en: 'Festive'
    },
    wide: {
      de: 'Weit',
      en: 'Wide'
    },
    black: {
      de: 'Schwarz',
      en: 'Black'
    },
    silver: {
      de: 'Silber',
      en: 'Silver'
    },
    casual: {
      de: 'Casual',
      en: 'Casual'
    },
    accessories: {
      de: 'Zubehör',
      en: 'Accessories'
    },
    misc: {
      de: 'Sonstiges',
      en: 'Other'
    },
    account: {
      de: 'Mein Konto',
      en: 'My account'
    },
    merchantLogin: {
      de: 'Händler-Login',
      en: 'Merchant Login'
    },
    returnPolicy: {
      de: '60 Tage Rückgaberecht',
      en: '60-Day Return Policy'
    },
    madeInGermany: {
      de: 'Versand binnen 24h',
      en: 'Shipping within 24h'
    },
    freeReturns: {
      de: 'Kostenloser Versand',
      en: 'Free shipping (DE)'
    },
    favs: {
      de: 'Favoriten',
      en: 'Favorites'
    },
    storeLocator: {
      de: 'Händlersuche',
      en: 'Store Locator'
    },
    onlineShops: {
      de: 'Online-Shops',
      en: 'Online-Shops'
    },
    catalog: {
      de: 'Katalog',
      en: 'Catalog'
    },
    news: {
      de: 'Diamant News',
      en: 'Diamant News'
    },
  };

  public routes = {
    favs: {
      de: 'favoriten',
      en: 'favorites'
    },
    ladies: {
      root: {
        de: 'damen',
        en: 'ladies'
      },
      latin: {
        de: 'damen/latein',
        en: 'ladies/latin'
      },
      standard: {
        de: 'damen/standard',
        en: 'ladies/standard'
      },
      tango: {
        de: 'damen/salsa-tango',
        en: 'ladies/salsa-tango'
      },
      trainer: {
        de: 'damen/trainer',
        en: 'ladies/trainer'
      },
      wedding: {
        de: 'damen/hochzeit',
        en: 'ladies/wedding',
      },
      comfy: {
        de: 'damen/bequeme-schuhe',
        en: 'ladies/comfy-shoes'
      },
      festive: {
        de: 'damen/festlich',
        en: 'ladies/festive'
      },
      black: {
        de: 'damen/schwarz',
        en: 'ladies/black'
      },
      silver: {
        de: 'damen/silber',
        en: 'ladies/silver'
      }
    },
    mens: {
      root: {
        de: 'herren',
        en: 'mens'
      },
      latin: {
        de: 'herren/latein',
        en: 'mens/latin'
      },
      standard: {
        de: 'herren/standard',
        en: 'mens/standard'
      },
      tango: {
        de: 'herren/salsa-tango',
        en: 'mens/salsa-tango'
      },
      ballroom: {
        de: 'herren/sneaker',
        en: 'mens/sneaker'
      },
      wedding: {
        de: 'herren/hochzeit',
        en: 'mens/wedding'
      },
      casual: {
        de: 'herren/casual',
        en: 'mens/casual'
      },
      comfy: {
        de: 'herren/bequem',
        en: 'mens/comfy'
      },
      wide: {
        de: 'herren/weit',
        en: 'mens/wide'
      }
    },
    children: {
      root: {
        de: 'kinder',
        en: 'children'
      },
      boys: {
        de: 'kinder/knaben',
        en: 'children/boys'
      },
      girls: {
        de: 'kinder/mädchen',
        en: 'children/girls'
      }
    },
    misc: {
      calendar: {
        de: 'kalender',
        en: 'calendar'
      },
      accessories: {
        de: 'zubehör',
        en: 'accessories'
      },
      new: {
        de: 'neu',
        en: 'new'
      },
      sale: {
        de: 'sale',
        en: 'sale'
      },
    },
    account: {
      de: 'dashboard',
      en: 'dashboard'
    },
    storeLocator: {
      de: 'storelocator',
      en: 'storelocator'
    },
    onlineShops: {
      de: 'online-shops',
      en: 'online-shops'
    },
    catalog: {
      de: 'tanzschuhe-katalog',
      en: 'dance-shoes-catalog'
    },
    news: {
      de: 'blog',
      en: 'blog'
    }
  };

  constructor(public os: OverlayService, public cs: CategoriesService, public route: ActivatedRoute, public ls: LanguageService, public loginService: LoginService) {
  }

  ngOnInit() {
  }
}
