/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./size-format-dropdown.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../language.pipe";
import * as i3 from "../../language.service";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@angular/common";
import * as i6 from "./size-format-dropdown.component";
import * as i7 from "../size/size.service";
var styles_SizeFormatDropdownComponent = [i0.styles];
var RenderType_SizeFormatDropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SizeFormatDropdownComponent, data: {} });
export { RenderType_SizeFormatDropdownComponent as RenderType_SizeFormatDropdownComponent };
export function View_SizeFormatDropdownComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.LanguagePipe, [i3.LanguageService]), (_l()(), i1.ɵeld(1, 0, null, null, 21, "div", [["class", "nav-item"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(2, 1720320, null, 3, i4.NgbDropdown, [i1.ChangeDetectorRef, i4.NgbDropdownConfig, i5.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2, [2, i4.NgbNavbar]], { placement: [0, "placement"] }, null), i1.ɵqud(603979776, 1, { _menu: 0 }), i1.ɵqud(603979776, 2, { _menuElement: 0 }), i1.ɵqud(603979776, 3, { _anchor: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 4, "a", [["aria-haspopup", "true"], ["class", "nav-link dropdown-toggle"], ["id", "navbarDropdown1"], ["ngbDropdownToggle", ""], ["role", "button"], ["style", "cursor: pointer"]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i4.NgbDropdownToggle, [i4.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[3, 4]], i4.NgbDropdownAnchor, null, [i4.NgbDropdownToggle]), (_l()(), i1.ɵted(9, null, [" ", " ", " "])), i1.ɵppd(10, 1), (_l()(), i1.ɵeld(11, 0, [[2, 0]], null, 11, "div", [["aria-labelledby", "navbarDropdown1"], ["class", "dropdown-menu"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, [[1, 4]], 1, i4.NgbDropdownMenu, [i4.NgbDropdown], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵeld(14, 0, null, null, 2, "a", [["class", "dropdown-item"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelect("EU", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(15, null, ["EU ", ""])), i1.ɵppd(16, 1), (_l()(), i1.ɵeld(17, 0, null, null, 2, "a", [["class", "dropdown-item"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelect("UK", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(18, null, ["UK ", ""])), i1.ɵppd(19, 1), (_l()(), i1.ɵeld(20, 0, null, null, 2, "a", [["class", "dropdown-item"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelect("US", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(21, null, ["US ", ""])), i1.ɵppd(22, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.placement; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).isOpen(); _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 7).dropdown.isOpen(); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.ss.format; var currVal_4 = i1.ɵunv(_v, 9, 1, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.text.size)); _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_5 = true; var currVal_6 = i1.ɵnov(_v, 12).dropdown.isOpen(); var currVal_7 = i1.ɵnov(_v, 12).placement; _ck(_v, 11, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = i1.ɵunv(_v, 15, 0, _ck(_v, 16, 0, i1.ɵnov(_v, 0), _co.text.size)); _ck(_v, 15, 0, currVal_8); var currVal_9 = i1.ɵunv(_v, 18, 0, _ck(_v, 19, 0, i1.ɵnov(_v, 0), _co.text.size)); _ck(_v, 18, 0, currVal_9); var currVal_10 = i1.ɵunv(_v, 21, 0, _ck(_v, 22, 0, i1.ɵnov(_v, 0), _co.text.size)); _ck(_v, 21, 0, currVal_10); }); }
export function View_SizeFormatDropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-size-format-dropdown", [], null, null, null, View_SizeFormatDropdownComponent_0, RenderType_SizeFormatDropdownComponent)), i1.ɵdid(1, 114688, null, 0, i6.SizeFormatDropdownComponent, [i7.SizeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SizeFormatDropdownComponentNgFactory = i1.ɵccf("app-size-format-dropdown", i6.SizeFormatDropdownComponent, View_SizeFormatDropdownComponent_Host_0, { placement: "placement" }, {}, []);
export { SizeFormatDropdownComponentNgFactory as SizeFormatDropdownComponentNgFactory };
