<div class="footer container-fluid">
  <div class="container">
    <div class="row d-print-none">
      <div class="d-none d-sm-block col-sm-4 main-footer">
        <h5><a [routerLink]="routes.companyInfo[ls.l]">{{text.company[ls.l]}}</a></h5>
        <ul>

          <li *ngFor="let infoPage of infoPages.company">
            <a [routerLink]="routes.infoPage[ls.l] + '/' + infoPage.slug">
              {{infoPage.title}}
            </a>
          </li>
          <li>
            <div class="contact-pair">
              <p>{{text.phone[ls.l]}}</p>
              <a href="tel:{{contact.tel}}">{{contact.telDisplay}}</a>
            </div>
          </li>
          <li>
            <div class="contact-pair">
              <p>{{text.email[ls.l]}}</p>
              <a href="mailto:{{contact.mail}}">{{contact.mail}}</a>
            </div>
          </li>
        </ul>
      </div>

      <div class="col-6 col-sm-4">
        <h5><a [routerLink]="routes.infoOverview[ls.l]">{{text.information[ls.l]}}</a></h5>
        <ul>
          <li>
            <a [routerLink]="routes.blog[ls.l]">Diamant-News</a>
          </li>
          <li *ngFor="let infoPage of infoPages.product_info">
            <a [routerLink]="routes.infoPage[ls.l] + '/' + infoPage.slug">
              {{infoPage.title}}
            </a>
          </li>
        </ul>
      </div>

      <div class="col-6 col-sm-4">
        <h5><a [routerLink]="routes.danceShoes[ls.l]">{{text.buyDanceShoes[ls.l]}}</a></h5>
        <ul role="menu">
          <li *ngFor="let infoPage of infoPages.dance_shoes">
            <a [routerLink]="routes.infoPage[ls.l] + '/' + infoPage.slug">
              {{infoPage.title}}
            </a>
          </li>
          <li class="mt-3">
            <a [routerLink]="routes.i18nVersion[ls.l]"
               (click)="ls.saveLang(routes.i18nLang[ls.l]); tester()">{{text.i18nVersion[ls.l]}}</a>
          </li>
        </ul>
      </div>
    </div>

    <hr class="divider d-print-none">

    <div class="sub-footer">
      <p *ngIf="loginService.isLoggedIn">{{text.disclaimerA[ls.l]}} {{text.disclaimerLink[ls.l]}}{{text.disclaimerB[ls.l]}}
      </p>
      <p class="mt-2 mt-md-0">{{text.disclaimerC[ls.l]}}</p>

      <div class="other-icons d-flex justify-content-center mt-3">
        <a class="mx-2" href="https://www.instagram.com/diamantdanceshoes" rel="noopener" target="_blank">
          <app-icon [type]="'social'">instagram</app-icon>
        </a>
        <a class="mx-2" href="https://www.facebook.com/Tanzschuhe" rel="noopener" target="_blank">
          <app-icon [type]="'social'">facebook</app-icon>
        </a>
        <a class="mx-2" href="https://twitter.com/tanzschuhe" rel="noopener" target="_blank">
          <app-icon [type]="'social'">twitter</app-icon>
        </a>
      </div>
    </div>
  </div>
</div>
