import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  public categoriesOverlay: boolean = false;
  public hideOverlay: boolean = false;

  constructor() {
  }

  showCategoriesOverlay() {
    this.categoriesOverlay = true;
  }

  hideCategoriesOverlay() {
    this.hideOverlay = true;
    setTimeout(() => {
        this.categoriesOverlay = false;
        this.hideOverlay = false;
      },
      300);
  }

  toggleCategoriesOverlay() {
    if (this.categoriesOverlay) {
      this.hideCategoriesOverlay();
    } else {
      this.showCategoriesOverlay();
    }
  }

}
