import {Injectable} from '@angular/core';
import * as io from 'socket.io-client';
import {LoginService} from './user/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  public socket;

  constructor(private loginService: LoginService) {
  }

  connect() {
    if (!this.socket/*|| this.socket.disconnected*/) {
      this.socket = io.connect();
      this.socket.on('refresh', () => {
        this.loginService.remoteAccountInfo();
      });
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }
}
