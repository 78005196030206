import { LanguageService } from '../../language.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { debounce, distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { LocalStorageService } from '../../local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../language.service";
import * as i3 from "../../local-storage.service";
export class LoginService {
    constructor(http, ls, storage) {
        this.http = http;
        this.ls = ls;
        this.storage = storage;
        this.loginPending = true;
        this.isLoggedIn = false;
        this.options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
            })
        };
        // Debounce remoteAccountInfo()
        this.$remoteAccountInfo = new Subject();
        this.$remoteAccountInfo.asObservable().pipe(debounce(() => timer(10))).subscribe(x => {
            this._remoteAccountInfo();
        });
        this._currentUserSubject = new BehaviorSubject(storage.retrieve('user'));
        this.$user = this._currentUserSubject.asObservable();
        this.remoteAccountInfo();
    }
    getUser() {
        return this.$user.pipe(distinctUntilChanged((prev, curr) => {
            if (prev === curr) {
                return true;
            }
            if (!prev || !curr) {
                return false;
            }
            prev.updated_at = curr.updated_at = undefined;
            return JSON.stringify(prev) === JSON.stringify(curr);
        }));
    }
    getPaymentInfo() {
        return this.http.get('/api/payments/stripe');
    }
    localLogin(email, password) {
        const body = {
            email: email,
            password: password
        };
        return this.http.post('/auth/local/login', body, this.options).pipe(map(x => {
            this.remoteAccountInfo();
            return x;
        }));
    }
    localSignup(email, password) {
        const body = {
            email: email,
            password: password,
            lang: this.ls.l
        };
        return this.http.post('/auth/local/signup', body, this.options);
    }
    logout() {
        this.storage.clear('user');
        this._currentUserSubject.next(undefined);
        return this.http.get('/auth/logout', this.options);
    }
    updateUser(user) {
        return this.http.put('/auth/account', user).pipe(map(x => {
            this.remoteAccountInfo();
            return x;
        }));
    }
    importCollections(fileToUpload, name) {
        const formData = new FormData();
        formData.append('collection', fileToUpload, name);
        return this.http.post('/api/collections', formData);
    }
    updateFavorites(user) {
        return this.http.put('/auth/favs', user).pipe(map(x => {
            this.remoteAccountInfo();
            return x;
        }));
    }
    remoteAccountInfo() {
        this.$remoteAccountInfo.next();
    }
    _remoteAccountInfo() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        });
        return this.http.get('/auth/account', { headers: headers }).subscribe(user => {
            user = user['status'] === 'not logged in' ? undefined : user;
            this.isLoggedIn = !!user;
            this.loginPending = false;
            this._currentUserSubject.next(user);
            this.storage.store('user', user);
        });
    }
}
LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LanguageService), i0.ɵɵinject(i3.LocalStorageService)); }, token: LoginService, providedIn: "root" });
