import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingBarService {

  public loading = false;

  constructor() {
  }

  public startLoading() {
    this.loading = true;
  }

  public stopLoading() {
    this.loading = false;
  }
}
