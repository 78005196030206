import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from './user/login/login.service';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private ls: LoginService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.ls.isLoggedIn || this.ls.loginPending) {
      const customReq = request.clone({
        setParams: {
          client: !this.ls.loginPending ? 'b2c' : this.randomString,
        }
      });
      return next.handle(customReq);
    } else {
      return next.handle(request);
    }
  }

  // This is used to generate a random client name to force cache bypassing while user login is still pending
  get randomString() {
    let result = '';
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charLen = chars.length;
    for (let i = 0; i < 4; i++) {
      result += chars.charAt(Math.floor(Math.random() * charLen));
    }
    return result;
  }
}
