import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  public showSearch = false;
  public focusSearch = false;

  constructor() {
  }

  activateSearch(focus = false) {
    this.showSearch = true;
    if (focus) {
      this.focusSearch = true;
    }
  }

  deactivateSearch() {
    setTimeout(() => {
      this.showSearch = false;
      this.focusSearch = false;
    }, 200);
  }
}
