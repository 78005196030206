import { Meta, Title } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
export class SeoService {
    constructor(title, meta) {
        this.title = title;
        this.meta = meta;
    }
    setTitle(title) {
        this.title.setTitle(title);
    }
    setMeta(meta) {
        this.meta.updateTag(meta);
    }
    setDescription(description) {
        this.meta.updateTag({ name: 'description', content: description });
    }
}
SeoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SeoService_Factory() { return new SeoService(i0.ɵɵinject(i1.Title), i0.ɵɵinject(i1.Meta)); }, token: SeoService, providedIn: "root" });
