/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/button/button.component.ngfactory";
import * as i3 from "../shared/button/button.component";
import * as i4 from "./not-found.component";
import * as i5 from "@angular/platform-browser";
var styles_NotFoundComponent = [i0.styles];
var RenderType_NotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["404 - Page not found"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Die gew\u00FCnschte Seite konnte leider nicht gefunden werden."])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-button", [], null, null, null, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(8, 114688, null, 0, i3.ButtonComponent, [], { text: [0, "text"], link: [1, "link"] }, null)], function (_ck, _v) { var currVal_0 = "Zur Startseite"; var currVal_1 = ""; _ck(_v, 8, 0, currVal_0, currVal_1); }, null); }
export function View_NotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i4.NotFoundComponent, [i5.Meta], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundComponentNgFactory = i1.ɵccf("app-not-found", i4.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
