/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toast.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./toast.component";
import * as i5 from "./toast.service";
var styles_ToastComponent = [i0.styles];
var RenderType_ToastComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastComponent, data: {} });
export { RenderType_ToastComponent as RenderType_ToastComponent };
function View_ToastComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ts.actionLink; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.ts.actionText; _ck(_v, 2, 0, currVal_1); }); }
function View_ToastComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "toast-message"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "hide-toast": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["timeIndicator", 1]], null, 3, "div", [["class", "time-indicator"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgStyleImpl, i3.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i3.NgStyle, [i3.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(11, { "animation-duration": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "toast-message"; var currVal_1 = _ck(_v, 3, 0, _co.ts.hideAnimation); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = (_co.ts.actionText !== ""); _ck(_v, 7, 0, currVal_3); var currVal_4 = _ck(_v, 11, 0, (_co.ts.currentDuration + "ms")); _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.ts.message; _ck(_v, 5, 0, currVal_2); }); }
export function View_ToastComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { timeIndicator: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ts.visible; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ToastComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toast", [], null, null, null, View_ToastComponent_0, RenderType_ToastComponent)), i1.ɵdid(1, 114688, null, 0, i4.ToastComponent, [i5.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToastComponentNgFactory = i1.ɵccf("app-toast", i4.ToastComponent, View_ToastComponent_Host_0, {}, {}, []);
export { ToastComponentNgFactory as ToastComponentNgFactory };
