export const text = {
  // search: {
  //   de: 'Suchbegriff',
  //   en: 'Search'
  // },
  // article: {
  //   de: 'Artikel',
  //   en: 'Article'
  // },
  // searchCategories: {
  //   de: 'Kategorien & Suche',
  //   en: 'Categories & Search'
  // }
};
