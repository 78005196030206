import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { LanguageService } from '../../language.service';
import { catchError, map } from 'rxjs/operators';
import { WebsocketService } from '../../websocket.service';
import * as i0 from "@angular/core";
import * as i1 from "./login.service";
import * as i2 from "@angular/router";
import * as i3 from "../../language.service";
import * as i4 from "../../websocket.service";
export class AuthGuard {
    constructor(ls, router, langS, wss) {
        this.ls = ls;
        this.router = router;
        this.langS = langS;
        this.wss = wss;
    }
    canActivate(next, state) {
        return this.ls.getUser().pipe(map(user => {
            if (!user) {
                this.ls.redirectedUrl = state.url;
                this.router.navigate([this.langS.rl + 'login']);
                return false;
            }
            else {
                this.wss.connect();
                return true;
            }
        }), catchError(() => {
            this.router.navigate([this.langS.rl + 'login']);
            return of(false);
        }));
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.LoginService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.LanguageService), i0.ɵɵinject(i4.WebsocketService)); }, token: AuthGuard, providedIn: "root" });
