import {animate, state, style, transition, trigger} from '@angular/animations';

// Shared animations for SizeSelect- and SizeDisplayComponent.
export const animations = [
  trigger('slideInOut', [
    state('in', style({
      height: '*',
    })),
    state('true', style({
      opacity: '0',
      height: '0px',
    })),
    transition('true => false', animate('400ms ease-in-out')),
    transition('false => true', animate('400ms ease-in-out')),
  ])
];

export const text = {
  size: {
    de: 'Größe',
    en: 'Size'
  },
  quantity: {
    de: 'Stückzahl',
    en: 'Quantity'
  },
  price: {
    de: 'Preis',
    en: 'Price'
  },
  show: {
    de: 'Größen anzeigen',
    en: 'show sizes'
  },
  amount: {
    de: 'Menge',
    en: 'Amount'
  },
  amountIn: {
    de: 'Menge in',
    en: 'Amount in'
  },
  stock: {
    low: {
      de: 'knapper Bestand',
      en: 'low stock'
    },
    mid: {
      de: 'lieferbar ab',
      en: 'deliverable from'
    },
    reaminingStock: {
      de: 'nur noch Restpaare',
      en: 'only remaining pairs',
    },
    extWarehouse: {
      de: 'Erweitertes Lager',
      en: 'Extended warehouse',
    },
    outOfStock: {
      de: 'nicht verfügbar',
      en: 'out of stock'
    },
    notInStock: {
      de: 'Sonderanfertigung - Lieferdatum ',
      en: 'non-stock item - delivery date '
    },
  },
  nonStockShoe: {
    de: 'Sonderanfertigung',
    en: 'Non-stock article'
  },
  extWarehouse: {
    de: 'Erweitertes Lager',
    en: 'Extended warehouse'
  },
  nonStockShoeInfo: {
    de: 'Dieses Modell ist kein Lagerschuh. Mindestbestellmenge sind 5 Paar.',
    en: 'This model is not a stock article. Minimum order quantity is 5 pairs.',
  },
  cart: {
    de: 'Warenkorb',
    en: 'Cart'
  },
  sumIn: {
    de: 'Summe in',
    en: 'Sum in'
  },
  minQuantity: {
    de: 'Mindestbestellmenge',
    en: 'minimum quantity'
  },
};
