import {Pipe, PipeTransform} from '@angular/core';
import {LanguageService} from './language.service';

@Pipe({
  name: 'rl'
})
export class RouteLanguagePipe implements PipeTransform {

  constructor(private ls: LanguageService) {
  }

  transform(value: any, args?: any): any {
    if (this.ls.l === 'de') {
      return value[this.ls.l];
    } else {
      return `${this.ls.l}/${value[this.ls.l]}`;
    }
  }
}
