import * as i0 from "@angular/core";
export class LoadingBarService {
    constructor() {
        this.loading = false;
    }
    startLoading() {
        this.loading = true;
    }
    stopLoading() {
        this.loading = false;
    }
}
LoadingBarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingBarService_Factory() { return new LoadingBarService(); }, token: LoadingBarService, providedIn: "root" });
