import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-tile-container',
  templateUrl: './tile-container.component.html',
  styleUrls: ['./tile-container.component.scss']
})
export class TileContainerComponent implements OnInit {

  @Input('url') url: string | null;

  constructor() { }

  ngOnInit() {
  }

}
