import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class StorelocatorService {
    constructor(http) {
        this.http = http;
    }
    findStores(lat, lng) {
        return this.http.get(`/api/storelocator/find/${lng}/${lat}`);
    }
}
StorelocatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorelocatorService_Factory() { return new StorelocatorService(i0.ɵɵinject(i1.HttpClient)); }, token: StorelocatorService, providedIn: "root" });
