import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  public visible: boolean = false;
  public message: string = '';
  public actionText: string = '';
  public actionLink: string = '';
  public hideAnimation: boolean = false;

  private defaultDuration = 3500;
  public currentDuration = 3500;

  constructor() {
  }

  public spawnToast(message: string, duration = this.defaultDuration) {
    this.currentDuration = duration;
    this.message = message;
    this.visible = true;

    setTimeout(() => {
        this.hideAnimation = true;
      },
      duration);

    setTimeout(() => {
        this.visible = false;
        this.hideAnimation = false;

        this.message = '';
        this.actionText = '';
        this.actionLink = '';
      },
      duration + 500);
  }

  public spawnActionToast(message: string, actionText: string, actionLink: string, duration = this.defaultDuration) {
    this.actionText = actionText;
    this.actionLink = actionLink;
    this.spawnToast(message, duration);
  }
}
