import * as i0 from "@angular/core";
export class OverlayService {
    constructor() {
        this.categoriesOverlay = false;
        this.hideOverlay = false;
    }
    showCategoriesOverlay() {
        this.categoriesOverlay = true;
    }
    hideCategoriesOverlay() {
        this.hideOverlay = true;
        setTimeout(() => {
            this.categoriesOverlay = false;
            this.hideOverlay = false;
        }, 300);
    }
    toggleCategoriesOverlay() {
        if (this.categoriesOverlay) {
            this.hideCategoriesOverlay();
        }
        else {
            this.showCategoriesOverlay();
        }
    }
}
OverlayService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OverlayService_Factory() { return new OverlayService(); }, token: OverlayService, providedIn: "root" });
