import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public languages = ['de', 'en'];
  public l = 'de';

  public showSwitchDialog = false;

  constructor(private router: Router, private route: ActivatedRoute, private ls: LocalStorageService) {
    this.getLanguageFromUrl();
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.getLanguageFromUrl();
        this.showSwitchDialog = this.needsPromtLang;
      }
    });
  }

  private getLanguageFromUrl() {
    let lang = 'de';
    const langRoute = location.pathname.split('/')[1];
    if (this.languages.includes(langRoute) === true) {
      lang = langRoute;
    }
    this.l = lang;
  }

  public transl(text: string): string {
    if (text !== null) {
      const langs = text.split(';');
      if (this.l === 'de') {
        return langs[0];
      } else if (this.l === 'en') {
        return langs[1];
      }
    }
    return '';
  }

  get rl() {
    if (this.l === 'de') {
      return '/';
    }
    return '/' + this.l + '/';
  }

  // get text
  public t(text) {
    return text[this.l];
  }

  // get route
  public r(route) {
    return this.rl + route[this.l];
  }

  /** LANGUAGE PROMT **/
  public saveLang(lang, route = false) {
    this.ls.store('lang', lang);

    if (route) {
      if (lang === 'de') {
        this.router.navigate(['']).then(() => {
          location.reload();
        });
      } else {
        this.router.navigate(['en']).then(() => {
          location.reload();
        });
      }
    }

    this.showSwitchDialog = false;
  }

  /**
   * Checks if user (selected) lang and current page lang differ
   * @returns {boolean}
   */
  get needsPromtLang() {
    const userLang = this.ls.retrieve('lang') || this.navigatorLang;
    const pageLang = this.l;
    if (/Prerender/i.test(navigator.userAgent)) {
      return false;
    }
    return ((userLang === 'de' && pageLang !== 'de') || (userLang !== 'de' && pageLang === 'de'));
  }

  private get navigatorLang() {
    if (navigator.language === 'de' || navigator.language.includes('de-')) {
      return 'de';
    } else {
      return navigator.language;
    }
  }

}
