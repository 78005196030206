import * as i0 from "@angular/core";
export class NavbarService {
    constructor() {
        this.showSearch = false;
        this.focusSearch = false;
    }
    activateSearch(focus = false) {
        this.showSearch = true;
        if (focus) {
            this.focusSearch = true;
        }
    }
    deactivateSearch() {
        setTimeout(() => {
            this.showSearch = false;
            this.focusSearch = false;
        }, 200);
    }
}
NavbarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavbarService_Factory() { return new NavbarService(); }, token: NavbarService, providedIn: "root" });
