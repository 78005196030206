import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() style: string = 'primary';
  @Input() text: string;
  @Input() size: string = 'lg';
  @Input() link: string;
  @Input() disabled: boolean;
  @Input() outline = false;
  @Input() icon: string;
  @Input() hoverIcon: string;

  public fillStyle = 'full';

  constructor() {
  }

  ngOnInit() {
    if (this.outline) {
      this.fillStyle = 'outline';
    }
  }

}
