<div class="categories" [ngClass]="{'hide': (os.categoriesOverlay && os.hideOverlay)}">
  <div class="categories-main">
    <hr class="divider top">
    <ul role="tablist" class="nav">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="ls.rl + routes.ladies.root[ls.l]" [routerLinkActive]="['active']"
           [ngClass]="{'last-active': cs.getActiveMainCategory() == 'Damen'}" #damen>{{text.ladies[ls.l]}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="ls.rl + routes.mens.root[ls.l]" [routerLinkActive]="['active']"
           [ngClass]="{'last-active': cs.getActiveMainCategory() == 'Herren'}" #herren>{{text.men[ls.l]}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="ls.rl + routes.children.root[ls.l]" [routerLinkActive]="['active']"
           [ngClass]="{'last-active': cs.getActiveMainCategory() == 'Kinder'}" #kinder>{{text.children[ls.l]}}</a>
      </li>
    </ul>

    <div class="categories-links">
      <ul *ngIf="cs.getActiveMainCategory() === 'Damen'">
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.latin[ls.l]"
               [routerLinkActive]="['active']">{{text.latin[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.standard[ls.l]"
               [routerLinkActive]="['active']">{{text.standard[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.tango[ls.l]"
               [routerLinkActive]="['active']">{{text.tango[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.trainer[ls.l]"
               [routerLinkActive]="['active']">{{text.trainer[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.wedding[ls.l]"
               [routerLinkActive]="['active']">{{text.bridal[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.comfy[ls.l]"
               [routerLinkActive]="['active']">{{text.comfy[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.festive[ls.l]"
               [routerLinkActive]="['active']">{{text.festive[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.black[ls.l]"
               [routerLinkActive]="['active']">{{text.black[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.ladies.silver[ls.l]"
               [routerLinkActive]="['active']">{{text.silver[ls.l]}}</a></li>
      </ul>

      <ul *ngIf="cs.getActiveMainCategory() === 'Herren'">
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.latin[ls.l]"
               [routerLinkActive]="['active']">{{text.latin[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.standard[ls.l]"
               [routerLinkActive]="['active']">{{text.standard[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.tango[ls.l]"
               [routerLinkActive]="['active']">{{text.tango[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.ballroom[ls.l]"
               [routerLinkActive]="['active']">{{text.ballroom[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.wedding[ls.l]"
               [routerLinkActive]="['active']">{{text.wedding[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.casual[ls.l]"
               [routerLinkActive]="['active']">{{text.casual[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.comfy[ls.l]"
               [routerLinkActive]="['active']">{{text.comfy[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.mens.wide[ls.l]"
               [routerLinkActive]="['active']">{{text.wide[ls.l]}}</a></li>
      </ul>

      <ul *ngIf="cs.getActiveMainCategory() === 'Kinder'">
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.children.boys[ls.l]"
               [routerLinkActive]="['active']">{{text.boys[ls.l]}}</a></li>
        <li><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.children.girls[ls.l]"
               [routerLinkActive]="['active']">{{text.girls[ls.l]}}</a></li>
      </ul>

      <div class="divider-wrapper">
        <div class="divider"></div>
        <span>{{text.misc[ls.l]}}</span>
        <div class="divider"></div>
      </div>
      <p *ngIf="cs.getActiveMainCategory() !== 'Favoriten'"><a (click)="os.hideCategoriesOverlay()"
                                                               [routerLink]="ls.rl + routes.misc.accessories[ls.l]"
                                                               [routerLinkActive]="['active']">{{text.accessories[ls.l]}}</a>
      </p>

      <p *ngIf="cs.getActiveMainCategory() !== 'Favoriten'"><a class="sale"
                                                               (click)="os.hideCategoriesOverlay()"
                                                               [routerLink]="ls.rl + routes.misc.sale[ls.l]"
                                                               [routerLinkActive]="['active']"
                                                               [ngClass]="{'last-active': cs.getActiveMainCategory() == 'Sale'}" #sale >
                                                               {{text.sale[ls.l]}}</a>
      </p>
      <p *ngIf="cs.getActiveMainCategory() !== 'Favoriten'"><a (click)="os.hideCategoriesOverlay()"
                                                               [routerLink]="ls.rl + routes.misc.new[ls.l]"
                                                               [routerLinkActive]="['active']"
                                                               [ngClass]="{'last-active': cs.getActiveMainCategory() == 'Neu'}" #neu >
                                                               {{text.new[ls.l]}}</a>
      </p>

      <p class="favorites" *ngIf="loginService.isLoggedIn"><a (click)="os.hideCategoriesOverlay()" [routerLink]="ls.rl + routes.favs[ls.l]"
                              [routerLinkActive]="['active']">{{text.favs[ls.l]}}</a></p>

      <div class="not-logged-in-wrapper d-sm-none" *ngIf="!loginService.isLoggedIn">
        <p><a (click)="os.hideCategoriesOverlay()" [routerLink]="routes.storeLocator | rl">{{text.storeLocator | l}}</a></p>
        <p><a (click)="os.hideCategoriesOverlay()" [routerLink]="routes.onlineShops | rl">{{text.onlineShops | l}}</a></p>
        <p><a (click)="os.hideCategoriesOverlay()" [routerLink]="routes.catalog | rl">{{text.catalog | l}}</a></p>
        <p><a (click)="os.hideCategoriesOverlay()" [routerLink]="routes.news | rl">{{text.news | l}}</a></p>
      </div>
    </div>
  </div>
  <div class="extra-nav-mobile">
    <div class="navigation">
      <div class="row" *ngIf="loginService.isLoggedIn">
        <app-size-format-dropdown placement="top-left"></app-size-format-dropdown>
      </div>
      <div class="row">
        <div class="col-8 account d-flex" [routerLink]="ls.rl + routes.account[ls.l]" *ngIf="loginService.isLoggedIn"
             (click)="os.hideCategoriesOverlay()">
          {{text.account | l}}
          <app-icon class="ml-1">account_circle</app-icon>
        </div>
        <div class="col-8 account d-flex" [routerLink]="ls.rl + routes.account[ls.l]" *ngIf="!loginService.isLoggedIn"
             (click)="os.hideCategoriesOverlay()">
          {{text.merchantLogin | l}}
        </div>
        <div class="col-4 language">
          <app-language-picker></app-language-picker>
        </div>
      </div>
    </div>
  </div>
</div>
