/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../categories/categories.component.ngfactory";
import * as i4 from "../../categories/categories.component";
import * as i5 from "./overlay.service";
import * as i6 from "../../categories/categories.service";
import * as i7 from "@angular/router";
import * as i8 from "../../../language.service";
import * as i9 from "../../../user/login/login.service";
import * as i10 from "./overlay.component";
var styles_OverlayComponent = [i0.styles];
var RenderType_OverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverlayComponent, data: {} });
export { RenderType_OverlayComponent as RenderType_OverlayComponent };
function View_OverlayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "overlay fixed-top"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "hide": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-categories", [], null, null, null, i3.View_CategoriesComponent_0, i3.RenderType_CategoriesComponent)), i1.ɵdid(7, 114688, null, 0, i4.CategoriesComponent, [i5.OverlayService, i6.CategoriesService, i7.ActivatedRoute, i8.LanguageService, i9.LoginService], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "backdrop"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.os.hideCategoriesOverlay() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "overlay fixed-top"; var currVal_1 = _ck(_v, 3, 0, (_co.os.categoriesOverlay && _co.os.hideOverlay)); _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 7, 0); }, null); }
export function View_OverlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverlayComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.os.categoriesOverlay; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_OverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-overlay", [], null, null, null, View_OverlayComponent_0, RenderType_OverlayComponent)), i1.ɵdid(1, 114688, null, 0, i10.OverlayComponent, [i5.OverlayService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OverlayComponentNgFactory = i1.ɵccf("app-overlay", i10.OverlayComponent, View_OverlayComponent_Host_0, {}, {}, []);
export { OverlayComponentNgFactory as OverlayComponentNgFactory };
