import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../language.service';
import {contact, routes, text} from './footer.i18n';
import {DirectusService} from '../../directus.service';
import {LoginService} from '../../user/login/login.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  public text = text;
  public contact = contact;
  public routes = routes;

  public infoPages: any = {};

  constructor(public ls: LanguageService, private directus: DirectusService, public loginService: LoginService) {
  }

  ngOnInit() {
    this.tester();
  }

  tester() {
    this.directus.loadFooterHierarchy()
      .subscribe(
        res => {
          this.infoPages = res;
        }
      );
  }
}
