import * as io from 'socket.io-client';
import { LoginService } from './user/login/login.service';
import * as i0 from "@angular/core";
import * as i1 from "./user/login/login.service";
export class WebsocketService {
    constructor(loginService) {
        this.loginService = loginService;
    }
    connect() {
        if (!this.socket /*|| this.socket.disconnected*/) {
            this.socket = io.connect();
            this.socket.on('refresh', () => {
                this.loginService.remoteAccountInfo();
            });
        }
    }
    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
        }
    }
}
WebsocketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WebsocketService_Factory() { return new WebsocketService(i0.ɵɵinject(i1.LoginService)); }, token: WebsocketService, providedIn: "root" });
