import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SearchService {
    constructor(http) {
        this.http = http;
        this.filterData = {
            'width': [],
            'heel': [],
            'material': [],
            'color': []
        };
        this.activeFilters = {
            'width': [],
            'heel': [],
            'material': [],
            'color': []
        };
        this.requestedPages = 1;
    }
    restoreDefaults() {
        this.requestedPages = 1;
        this.pageTitle = '';
        this.pageDesc = '';
        this.deeperCategories = [];
    }
    resetFilters() {
        this.filterData = {
            'width': [],
            'heel': [],
            'material': [],
            'color': []
        };
        this.activeFilters = {
            'width': [],
            'heel': [],
            'material': [],
            'color': []
        };
    }
    getAllProducts(limit, offset) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const params = this.getHttpParams(limit, offset);
        const options = { headers: headers, params: params };
        return this.http.get('/api/products', options);
    }
    getCategoryProducts(categories, limit, offset) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const params = this.getHttpParams(limit, offset);
        const options = { headers: headers, params: params };
        return this.http.get('/api/products/' + categories, options);
    }
    getProduct(id) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: headers };
        return this.http.get('/api/product/' + id, options);
    }
    getProductByIds(ids, limit, offset) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const params = this.getHttpParams(limit, offset);
        const options = { headers: headers, params: params };
        return this.http.get('/api/products-by-id/' + ids.join(','), options);
    }
    search(searchTerm, limit, offset) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const params = this.getHttpParams(limit, offset);
        const options = { headers: headers, params: params };
        return this.http.get('/api/search/' + searchTerm, options);
    }
    getHttpParams(limit, offset, favorites) {
        let params = new HttpParams();
        if (limit !== undefined) {
            params = params.set('limit', String(limit));
        }
        if (offset !== undefined) {
            params = params.set('offset', String(offset));
        }
        if (favorites) {
            params = params.set('favorites', JSON.stringify(favorites));
        }
        this.activeFilters.width.forEach(width => {
            params = params.append('width', width.de);
        });
        this.activeFilters.heel.forEach(heel => {
            params = params.append('heel', heel.de);
        });
        this.activeFilters.material.forEach(material => {
            params = params.append('material', material.de);
        });
        this.activeFilters.color.forEach(color => {
            params = params.append('color', color.de);
        });
        return params;
    }
    get hasActiveFilters() {
        return this.activeFilters.width.length > 0 ||
            this.activeFilters.heel.length > 0 ||
            this.activeFilters.material.length > 0 ||
            this.activeFilters.color.length > 0;
    }
}
SearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchService_Factory() { return new SearchService(i0.ɵɵinject(i1.HttpClient)); }, token: SearchService, providedIn: "root" });
