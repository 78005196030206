import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SeoService} from '../seo.service';
import {routes, text} from './landing.i18n';
import {LanguageService} from '../language.service';
import {Meta} from '@angular/platform-browser';
import {ToastService} from '../shared/toast/toast.service';
import {DirectusService} from '../directus.service';
import {LandingPageTile, Page} from '../../models/landingPageTile';
import {ActivatedRoute} from '@angular/router';
import {LoginService} from '../user/login/login.service';

declare let google: any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  public text = text;
  public routes = routes;
  public email: string;

  public tiles: LandingPageTile[];

  orgaStructuredData = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    'name': 'Diamant',
    'url': 'https://www.diamant.net/',
    'logo': 'https://www.diamant.net/assets/logo.png',
    'sameAs': [
      'https://www.instagram.com/diamantdanceshoes/',
      'https://www.facebook.com/Tanzschuhe',
      'https://twitter.com/tanzschuhe'
    ],
    'contactPoint': [
      {
        '@type': 'ContactPoint',
        'contactOption': 'TollFree',
        'telephone': '+49-(0)6196-594544',
        'contactType': 'customer service'
      }
    ]
  };

  websiteStructuredData = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    'url': 'https://www.diamant.net/',
    'potentialAction': {
      '@type': 'SearchAction',
      'target': 'https://www.diamant.net/suche/{search_term_string}',
      'query-input': 'required name=search_term_string'
    }
  };

  constructor(private http: HttpClient, private seo: SeoService, public ls: LanguageService, private ts: ToastService, private meta: Meta, private directus: DirectusService, private route: ActivatedRoute, private loginService: LoginService) {
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      if (data.tiles) {
        this.loadTiles(data.tiles);
      } else {
        this.loginService.$user.subscribe(user => {
          this.loadTiles(user ? Page.b2b : Page.b2c);
        });
      }
    });

    this.seo.setTitle(this.ls.t(this.text.seoTitle));
    this.seo.setDescription(this.ls.t(this.text.seoDescription));
  }

  loadTiles(page: Page = Page.danceshoes) {
    this.directus.loadLandingPageTiles(page)
      .subscribe(res => {
        this.tiles = res;
      });
  }
}
