/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./language-picker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./language-picker.component";
import * as i5 from "../../../language.service";
var styles_LanguagePickerComponent = [i0.styles];
var RenderType_LanguagePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LanguagePickerComponent, data: {} });
export { RenderType_LanguagePickerComponent as RenderType_LanguagePickerComponent };
function View_LanguagePickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "lang"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.ls.saveLang("de", true) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["DE"]))], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_LanguagePickerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "lang dummy"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["DE"]))], null, null); }
function View_LanguagePickerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "lang"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.ls.saveLang("en", true) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["EN"]))], function (_ck, _v) { var currVal_2 = "en"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_LanguagePickerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "lang dummy"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["EN"]))], null, null); }
export function View_LanguagePickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "language-picker"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguagePickerComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguagePickerComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "divider"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguagePickerComponent_3)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguagePickerComponent_4)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.ls.l !== "de"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.ls.l === "de"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.ls.l !== "en"); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.ls.l === "en"); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_LanguagePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-language-picker", [], null, null, null, View_LanguagePickerComponent_0, RenderType_LanguagePickerComponent)), i1.ɵdid(1, 114688, null, 0, i4.LanguagePickerComponent, [i5.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LanguagePickerComponentNgFactory = i1.ɵccf("app-language-picker", i4.LanguagePickerComponent, View_LanguagePickerComponent_Host_0, {}, {}, []);
export { LanguagePickerComponentNgFactory as LanguagePickerComponentNgFactory };
