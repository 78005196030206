import * as i0 from "@angular/core";
export class ToastService {
    constructor() {
        this.visible = false;
        this.message = '';
        this.actionText = '';
        this.actionLink = '';
        this.hideAnimation = false;
        this.defaultDuration = 3500;
        this.currentDuration = 3500;
    }
    spawnToast(message, duration = this.defaultDuration) {
        this.currentDuration = duration;
        this.message = message;
        this.visible = true;
        setTimeout(() => {
            this.hideAnimation = true;
        }, duration);
        setTimeout(() => {
            this.visible = false;
            this.hideAnimation = false;
            this.message = '';
            this.actionText = '';
            this.actionLink = '';
        }, duration + 500);
    }
    spawnActionToast(message, actionText, actionLink, duration = this.defaultDuration) {
        this.actionText = actionText;
        this.actionLink = actionLink;
        this.spawnToast(message, duration);
    }
}
ToastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToastService_Factory() { return new ToastService(); }, token: ToastService, providedIn: "root" });
