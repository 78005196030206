<div class="nav-item" ngbDropdown [placement]="placement">
  <a class="nav-link" style="cursor: pointer" ngbDropdownToggle id="navbarDropdown1" role="button">
    {{ss.format}} {{text.size | l}}
  </a>
  <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
    <a class="dropdown-item" href="#" (click)="onSelect('EU', $event)">EU {{text.size | l}}</a>
    <a class="dropdown-item" href="#" (click)="onSelect('UK', $event)">UK {{text.size | l}}</a>
    <a class="dropdown-item" href="#" (click)="onSelect('US', $event)">US {{text.size | l}}</a>
  </div>
</div>
