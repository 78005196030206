import { CategoriesService } from './shared/categories/categories.service';
import * as i0 from "@angular/core";
import * as i1 from "./shared/categories/categories.service";
export class AnalyticsService {
    constructor(catS) {
        this.catS = catS;
    }
    addPageView(url) {
        if (!this.isPrerender()) {
            ga('set', 'page', url);
            ga('send', 'pageview');
        }
    }
    addImpression(product, list) {
        if (!this.isPrerender()) {
            const impression = {
                id: product.articleNumber,
                name: product.name,
                price: product.totalPrice,
                category: this.catS.getCategoryHierarchy(product.categories).join('/')
            };
            if (list) {
                impression.list = list;
            }
            ga('ec:addImpression', impression);
        }
    }
    click(product, list) {
        ga('ec:addProduct', this.gaAddProduct(product));
        ga('ec:setAction', 'click');
        ga('send', 'event', 'UX', 'click', 'Click product');
    }
    detail(product, list) {
        if (!this.isPrerender()) {
            ga('ec:addProduct', this.gaAddProduct(product));
            ga('ec:setAction', 'detail');
            ga('send', 'event', 'Product Detail', 'viewed');
        }
    }
    add(product) {
        ga('ec:addProduct', this.gaAddProduct(product));
        ga('ec:setAction', 'add');
        ga('send', 'event', 'UX', 'click', 'Add to cart');
    }
    remove(product) {
        ga('ec:addProduct', this.gaAddProduct(product));
        ga('ec:setAction', 'remove');
        ga('send', 'event', 'UX', 'click', 'Remove from cart');
    }
    initiateCheckout(products) {
        products.map(p => {
            ga('ec:addProduct', this.gaAddProduct(p));
        });
        ga('ec:setAction', 'checkout', {
            step: 1,
            option: 'Login'
        });
        ga('send', 'event', 'Checkout', 'Initiate');
    }
    editAddress() {
        ga('ec:setAction', 'checkout', { 'step': 2 });
        ga('send', 'event', 'Checkout', 'Address');
    }
    selectPayment(paymentType) {
        ga('ec:setAction', 'checkout', { 'step': 3, 'option': paymentType });
        ga('send', 'event', 'Checkout', 'Payment');
    }
    /*selectShipping(shippingType) {
      ga('ec:setAction', 'checkout', {'step': 3, 'option': shippingType});
      ga('send', 'event', 'Checkout', 'Shipment');
    }*/
    overview() {
        ga('ec:setAction', 'checkout', { 'step': 4 });
        ga('send', 'event', 'Checkout', 'Overview');
    }
    purchase(products, orderNum, revenue, shipping) {
        products.map(p => {
            ga('ec:addProduct', this.gaAddProduct(p));
        });
        ga('set', 'currencyCode', 'EUR');
        ga('ec:setAction', 'purchase', {
            id: orderNum,
            revenue: revenue,
            shipping: shipping,
            tax: 0
        });
        ga('send', 'event', 'UX', 'click', 'Order');
    }
    gaAddProduct(product) {
        return {
            id: product.articleNumber,
            name: product.name,
            price: product.totalPrice,
            category: this.catS.getCategoryHierarchy(product.categories).join('/'),
            quantity: product.amount ? product.amount : 1
        };
    }
    checkoutFailed() {
    }
    isPrerender() {
        return (/Prerender/i.test(navigator.userAgent));
    }
}
AnalyticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.ɵɵinject(i1.CategoriesService)); }, token: AnalyticsService, providedIn: "root" });
