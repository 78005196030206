import {Pipe, PipeTransform} from '@angular/core';
import {LanguageService} from './language.service';

@Pipe({
  name: 'l'
})
export class LanguagePipe implements PipeTransform {

  constructor(private ls: LanguageService) {
  }

  transform(value: any, args?: any): any {
    return value[this.ls.l];
  }

}
