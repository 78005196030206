export class Product {
  protected _id: number;
  protected _articleNumber: string;
  protected _name: string;
  protected _price: number;
  protected _bulkPrices: BulkPrice[];
  protected _categories: string[];
  protected _groups: string[];
  protected _sizeType: string;
  protected _sizes: Size[];
  protected _properties: any;
  protected _title: any;
  protected _description: any;
  protected _url: string;
  protected _image: string;
  protected _amount: number;
  protected _minOrderQuantity: number;
  protected _isStock: boolean;

  protected _size: number[];

  constructor(data?: any) {
    this.price = 0;
    if (data !== undefined && (data.id !== undefined || data.articleNumber !== undefined)) {
      this.id = data.id || data.articleId;
      this.articleNumber = data.articleNumber;
      this.name = data.name;
      this.groups = data.groups;
      this.categories = data.categories;
      this.sizeType = data.sizeType;
      this.sizes = data.sizes;
      this.properties = data.properties;
      this.title = data.title;
      this.description = data.description;
      this.url = data.url;
      this.image = data.image;
      this.bulkPrices = data.bulkPrices;
      this.minOrderQuantity = data.minOrderQuantity;
      this.isStock = data.isStock;
      if (data.size) {
        this.size = data.size;
      }
      this.price = data.price;
      this.amount = data.amount;
    } else if (data !== undefined && data._id !== undefined) {
      this.id = data._id;
      this.articleNumber = data._articleNumber;
      this.name = data._name;
      this.price = data._price;
      this.categories = data._categories;
      this.sizeType = data._sizeType;
      this.sizes = data._sizes;
      this.properties = data._properties;
      this.title = data._title;
      this.description = data._description;
      this.url = data._url;
      this.image = data._image;
      this.amount = data._amount;
      this.bulkPrices = data._bulkPrices;
      this.minOrderQuantity = data._minOrderQuantity;
      this.isStock = data._isStock;

      if (data._size) {
        this.size = data._size;
      }
    }
  }

  // GETTERS AND SETTERS
  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get groups(): string[] {
    return this._groups;
  }

  set groups(value: string[]) {
    this._groups = value;
  }

  get articleNumber(): string {
    return this._articleNumber;
  }

  set articleNumber(value: string) {
    this._articleNumber = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get categories(): string[] {
    return this._categories;
  }

  set categories(value: string[]) {
    this._categories = value;
  }

  get sizeType(): string {
    return this._sizeType;
  }

  set sizeType(value: string) {
    this._sizeType = value;
  }

  get properties(): any {
    return this._properties;
  }

  set properties(value: any) {
    this._properties = value;
  }

  get title(): any {
    return this._title;
  }

  set title(value: any) {
    this._title = value;
  }

  get description(): any {
    return this._description;
  }

  set description(value: any) {
    this._description = value;
  }

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }

  get image(): string {
    return this._image;
  }

  set image(value: string) {
    this._image = value;
  }

  get amount() {
    if (this.hasSizes && this._size && this._size.reduce) {
      return this._size.reduce((pv, cv) => {
        return (pv || 0) + (cv || 0);
      }, 0);
    } else {
      return this._amount;
    }
  }

  set amount(value: number) {
    if (!this.hasSizes) {
      this._amount = value;
    }
  }

  set price(price: number) {
    this._price = price;
  }

  get price(): number {
    if (!this.bulkPrices || this.bulkPrices.length === 0) {
      return this.basePrice;
    } else {
      let lastAmount = 0;
      let price = this.basePrice;
      for (const p of this.bulkPrices) {
        if (this.amount >= p.amount && p.amount > lastAmount) {
          lastAmount = p.amount;
          price = p.price;
        }
      }
      return price;
    }
  }

  get basePrice(): number {
    return this._price;
  }

  set bulkPrices(bulkPrices: any) {
    this._bulkPrices = bulkPrices;
  }

  get bulkPrices() {
    return this._bulkPrices;
  }

  get totalPrice() {
    return this.amount ? this.price * this.amount : this.price;
  }

  set size(size: number[]) {
    this._size = size;
  }

  get size(): number[] {
    return this._size;
  }

  set sizes(sizes: Size[]) {
    this._sizes = sizes;
  }

  get sizes() {
    return this._sizes;
  }

  set isStock(isStock: boolean) {
    this._isStock = isStock;
  }

  get isStock(): boolean {
    return this._isStock || this.isAccessory ? true : this.hasSizes && this.sizes && this.sizes[0].status !== -1;
  }

  get isAccessory(): boolean {
    return (this.categories && this.categories[0] === 'Z01');
  }

  get hasSizes(): boolean {
    if (this.sizeType) {
      return this.sizeType.trim() !== '';
    } else {
      return false;
    }
  }

  set minOrderQuantity(quantity: number) {
    this._minOrderQuantity = 1;

    if (quantity && quantity > 1) {
      this._minOrderQuantity = quantity;
    } else if (!this.isStock) {
      this._minOrderQuantity = 5;
    }
  }

  get minOrderQuantity(): number {
    return this._minOrderQuantity;
  }

  get isBuyable(): boolean {
    return this.amount >= this.minOrderQuantity;
  }

  /** Return JSON presentation of Product eliminating underscores. */
  public toJSON() {
    return {
      id: this._id,
      articleNumber: this._articleNumber,
      name: this._name,
      price: this._price,
      groups: this._groups,
      categories: this._categories,
      sizeType: this._sizeType,
      sizes: this._sizes,
      size: this._size,
      properties: this._properties,
      title: this._title,
      description: this._description,
      url: this._url,
      image: this._image,
      amount: this._amount
    };
  }

}

// LeanProduct is a Product with reduced data (e.g. in order history items).
export class LeanProduct extends Product {

  private _isStockProduct: boolean;
  private _posnr: number;
  private _vanillaPrice: number;

  constructor(data?: any) {
    super(data);
    this.isStockProduct = data.isStockShoe;
    this.posnr = data.posnr;
    this.vanillaPrice = data.vanilla_price;
  }

  // GETTERS AND SETTERS.
  get posnr(): number {
    return this._posnr;
  }

  set posnr(posnr: number) {
    this._posnr = posnr;
  }

  get vanillaPrice(): number {
    return this._vanillaPrice;
  }

  set vanillaPrice(vanillaPrice: number) {
    this._vanillaPrice = vanillaPrice;
  }

  // OVERWRITE PRODUCT CLASS MEMBERS.
  get isStockProduct(): boolean {
    return this._isStockProduct;
  }

  set isStockProduct(isStockProduct: boolean) {
    this._isStockProduct = isStockProduct;
  }

  get title(): any {
    return this._title ? this._title : {
      de: this._id,
      en: this._id
    };
  }

  set title(title: any) {
    this._title = title;
  }
}

export class Size {
  status: number;
  available: Date | null;
}

export interface BulkPrice {
  price: number;
  amount: number;
}
