<div class="container mt-2 landing">
  <div class="row mx-md-n2">
    <div class="col-lg-{{tile.columns * 4}} col-md-{{tile.columns < 3 ? '6' : '12'}} mb-4 px-md-2" *ngFor="let tile of tiles">
      <app-tile-container [url]="tile.url">
        <div class="card" [ngClass]="{'category-tile': tile.category_tile}">
          <div *ngIf="tile.image" [ngClass]="{'card-image-top': !tile.image_tile, 'card-img': tile.image_tile}">
            <img [src]="tile.image" class="tile-img">
          </div>
          <div [ngClass]="{'card-body': !tile.image_tile, 'card-img-overlay': tile.image_tile}">
            <h5 class="card-title" *ngIf="tile.title" [ngClass]="{'mb-0': (!tile.content && !tile.call_to_action)}">{{tile.title}}</h5>
            <div class="card-text" *ngIf="tile.content" [innerHTML]="tile.content"></div>
            <button class="btn btn-primary mt-1" *ngIf="tile.call_to_action">{{tile.call_to_action}}</button>
          </div>
        </div>
      </app-tile-container>
    </div>
  </div>
</div>

<ngx-json-ld [json]="orgaStructuredData"></ngx-json-ld>
<ngx-json-ld [json]="websiteStructuredData"></ngx-json-ld>
