<button *ngIf="disabled || link == undefined" class="button btn button-{{style}} btn-{{size}} button-{{fillStyle}}"
        [disabled]="disabled">
  <span *ngIf="text" [ngStyle]="{'margin-right.em': icon || hoverIcon ? 0.4 : 0}">{{text}}</span>
  <app-icon class="unhoverIcon" *ngIf="icon">{{icon}}</app-icon>
  <app-icon class="hoverIcon" *ngIf="hoverIcon">{{hoverIcon ? hoverIcon : icon}}</app-icon>
</button>
<a *ngIf="!disabled && link != undefined" [routerLink]="'/'+link"
   class="button btn button-{{style}} btn-{{size}} button-{{fillStyle}}">
  <span *ngIf="text">{{text}}</span>
  <app-icon class="unhoverIcon" *ngIf="icon">{{icon}}</app-icon>
  <app-icon class="hoverIcon" *ngIf="hoverIcon">{{hoverIcon ? hoverIcon : icon}}</app-icon>
</a>
