import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private title: Title, private meta: Meta) {
  }

  public setTitle(title: string) {
    this.title.setTitle(title);
  }

  public setMeta(meta: any) {
    this.meta.updateTag(meta);
  }

  public setDescription(description: string) {
    this.meta.updateTag({name: 'description', content: description});
  }
}
