import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../language.service';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss']
})
export class LanguagePickerComponent implements OnInit {

  constructor(public ls: LanguageService) {
  }

  ngOnInit() {
  }

}
