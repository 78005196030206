export const text = {
  search: {
    de: 'Suchbegriff',
    en: 'Search'
  },
  loading: {
    de: 'Laden...',
    en: 'Loading...'
  },
  showAll: {
    de: 'Alle Ergebnisse anzeigen',
    en: 'Show all results'
  },
  results: {
    de: 'Ergebnisse',
    en: 'Results'
  },
  noHits: {
    de: 'Keine Ergebnisse',
    en: 'No results'
  },
  moreCharacters: {
    de: 'Bitte mindestens 3 Zeichen eingeben',
    en: 'Please enter at least 3 characters'
  }
};

export const routes = {
  search: {
    de: 'suche',
    en: 'search'
  }
};
