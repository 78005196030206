import {Injectable} from '@angular/core';
import {LanguageService} from '../../language.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {debounce, distinctUntilChanged, map} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subject, timer} from 'rxjs';
import {LocalStorageService} from '../../local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public loginPending: boolean = true;
  public isLoggedIn: boolean = false;
  public redirectedUrl: string;
  public $user: Observable<any>; // <User>
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
    })
  };
  private _currentUserSubject: BehaviorSubject<any>; // <User>
  private $remoteAccountInfo: Subject<boolean>;

  constructor(private http: HttpClient, private ls: LanguageService, private storage: LocalStorageService) {
    // Debounce remoteAccountInfo()
    this.$remoteAccountInfo = new Subject<boolean>();
    this.$remoteAccountInfo.asObservable().pipe(
      debounce(() => timer(10))
    ).subscribe(x => {
      this._remoteAccountInfo();
    });

    this._currentUserSubject = new BehaviorSubject<any>(storage.retrieve('user'));
    this.$user = this._currentUserSubject.asObservable();
    this.remoteAccountInfo();
  }

  getUser(): Observable<any> {
    return this.$user.pipe(distinctUntilChanged((prev, curr) => {
      if (prev === curr) {
        return true;
      }
      if (!prev || !curr) {
        return false;
      }
      prev.updated_at = curr.updated_at = undefined;
      return JSON.stringify(prev) === JSON.stringify(curr);
    }));
  }

  getPaymentInfo() {
    return this.http.get('/api/payments/stripe');
  }

  localLogin(email: string, password: string) {
    const body: any = {
      email: email,
      password: password
    };
    return this.http.post('/auth/local/login', body, this.options).pipe(map(
      x => {
        this.remoteAccountInfo();
        return x;
      }
    ));
  }

  localSignup(email: string, password: string) {
    const body: any = {
      email: email,
      password: password,
      lang: this.ls.l
    };

    return this.http.post('/auth/local/signup', body, this.options);
  }

  logout() {
    this.storage.clear('user');
    this._currentUserSubject.next(undefined);
    return this.http.get('/auth/logout', this.options);
  }

  updateUser(user: any) {
    return this.http.put('/auth/account', user).pipe(map(
      x => {
        this.remoteAccountInfo();
        return x;
      }
    ));
  }

  importCollections(fileToUpload: File, name: string) {
    const formData: FormData = new FormData();
    formData.append('collection', fileToUpload, name);
    return this.http.post('/api/collections', formData);
  }

  updateFavorites(user: any) {
    return this.http.put('/auth/favs', user).pipe(map(
      x => {
        this.remoteAccountInfo();
        return x;
      }
    ));
  }

  public remoteAccountInfo() {
    this.$remoteAccountInfo.next();
  }

  private _remoteAccountInfo() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
    });

    return this.http.get('/auth/account', {headers: headers}).subscribe(
      user => {
        user = user['status'] === 'not logged in' ? undefined : user;
        this.isLoggedIn = !!user;
        this.loginPending = false;
        this._currentUserSubject.next(user);
        this.storage.store('user', user);
      });
  }
}
