<div class="container">
  <div  class="input-group">
    <input type="text" class="form-control address-input" autocapitalize="off" spellcheck="off"
           id="address" placeholder="Adresse" aria-label="Adresse"
           aria-describedby="basic-addon2"  [(ngModel)]="savedAddress">
  </div>
</div>
<div class="container">
  <div class="row location-border mx-0">
    <div class="col-md-5 scroll px-0">
      <div *ngIf="searched == false" [ngClass]="{'d-flex justify-content-center align-items-center no-location-box': searched == false}" class="d-flex justify-content-center">
        <div class="card card-width no-location-wrapper">
          <div class="card-body d-flex justify-content-center">
            <div class="card-title">
              <p class="mb-0 mt-2 no-location-text">Bitte geben Sie eine gültige Adresse ein</p>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-start" *ngFor="let store of stores">
        <div class="card  shop-cards">
          <div *ngIf="searched == true" class="card-body" id="{{store.addressNumber}}"
               [ngClass]="{'card-focus': compareLat == store.location.coordinates[1]}">
            <div class="d-flex flex-row justify-content-between">
              <h2 class="h5 text-black card-title">{{store.shopName}}</h2>
              <a target="_blank"
                 href="https://www.google.com/maps/search/?api=1&query={{store.shopName}} {{store.location.coordinates[1]}},{{store.location.coordinates[0]}}"
                 class="route m-0">Route</a>
            </div>
            <div class="adjust-paragraph">
              <p class="card-text">{{store.zip}} {{store.city}}</p>
              <p class="card-text mb-4">{{store.streetNum}}</p>
              <div class="d-flex flex-row">
                <p>Tel.:</p>
                <a target="_blank" class="card-text mail-tel ml-1" href="tel:{{store.phone}}">{{store.phone}}</a>
              </div>
              <div class="d-flex flex-row mb-3">
                <p>E-Mail:</p>
                <a target="_blank" class="card-text mail-tel ml-1" href="mailto:{{store.email}}">{{store.email}}</a>
              </div>
              <div class="d-flex flex-row justify-content-between">
                <a target="_blank" [href]="store.website" class="">{{store.website}}</a>
                <p class="text-black distance mb-0">{{((store.distance / 1000) | number : '1.2-2').replace('.',',')}} km</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-7 px-0">
      <agm-map [zoom]="currZoom" [latitude]="this.lat" [longitude]="this.lng">
        <agm-marker *ngIf="currZoom != 2"
                    iconUrl="/assets/maps/blue-pin.png"
                    [latitude]="this.lat"
                    [longitude]="this.lng"></agm-marker>
        <agm-marker (markerClick)="selectMarker(store)"
                    *ngFor="let store of stores"
                    [latitude]="store.location.coordinates[1]"
                    [longitude]="store.location.coordinates[0]"></agm-marker>
      </agm-map>
    </div>
  </div>
</div>
