import { Product } from './product';

export class Collection {
  private _name: string;
  private _products: Product[] = [];

  constructor(name: string, products?: Product[]) {
    this._name = name;
    if (products === undefined) {
      this._products = [];
    } else {
      this.products = products;
    }
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get products(): Product[] {
    return this._products;
  }

  set products(products: Product[]) {
    for (let i = 0; i < products.length; i++) {
      this._products[i] = new Product(products[i]);
    }
  }

  /** Return JSON presentation of Collection eliminating underscores. */
  public toJSON() {
    const productsJSON = [];
    for (const product of this._products) {
      productsJSON.push(product.toJSON());
    }
    return {
      name: this._name,
      products: productsJSON
    };
  }
}
