import {Component, OnInit} from '@angular/core';
import {CartService} from '../../cart/cart.service';
import {LoginService} from '../../user/login/login.service';
import {OverlayService} from './overlay/overlay.service';
import {LanguageService} from '../../language.service';
import {routes, text} from './navbar.i18n';
import {NavbarService} from './navbar.service';
import {CategoriesService} from '../categories/categories.service';
import {merge, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public routes = routes;
  public text = text;

  constructor(public catS: CategoriesService, public cs: CartService, public ls: LoginService, public os: OverlayService, public langS: LanguageService, public ns: NavbarService) {
  }

  ngOnInit() {
  }

  getAccountRouting() {
    return merge(
      of('login'),
      this.ls.getUser().pipe(
        map(user => {
          return this.langS.rl + (user !== undefined ? 'dashboard' : 'login');
        }))
    );
  }

  public isIE() {
    const userAgent = navigator.userAgent;
    return userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1;
  }

}
