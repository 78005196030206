import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./local-storage.service";
export class LanguageService {
    constructor(router, route, ls) {
        this.router = router;
        this.route = route;
        this.ls = ls;
        this.languages = ['de', 'en'];
        this.l = 'de';
        this.showSwitchDialog = false;
        this.getLanguageFromUrl();
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.getLanguageFromUrl();
                this.showSwitchDialog = this.needsPromtLang;
            }
        });
    }
    getLanguageFromUrl() {
        let lang = 'de';
        const langRoute = location.pathname.split('/')[1];
        if (this.languages.includes(langRoute) === true) {
            lang = langRoute;
        }
        this.l = lang;
    }
    transl(text) {
        if (text !== null) {
            const langs = text.split(';');
            if (this.l === 'de') {
                return langs[0];
            }
            else if (this.l === 'en') {
                return langs[1];
            }
        }
        return '';
    }
    get rl() {
        if (this.l === 'de') {
            return '/';
        }
        return '/' + this.l + '/';
    }
    // get text
    t(text) {
        return text[this.l];
    }
    // get route
    r(route) {
        return this.rl + route[this.l];
    }
    /** LANGUAGE PROMT **/
    saveLang(lang, route = false) {
        this.ls.store('lang', lang);
        if (route) {
            if (lang === 'de') {
                this.router.navigate(['']).then(() => {
                    location.reload();
                });
            }
            else {
                this.router.navigate(['en']).then(() => {
                    location.reload();
                });
            }
        }
        this.showSwitchDialog = false;
    }
    /**
     * Checks if user (selected) lang and current page lang differ
     * @returns {boolean}
     */
    get needsPromtLang() {
        const userLang = this.ls.retrieve('lang') || this.navigatorLang;
        const pageLang = this.l;
        if (/Prerender/i.test(navigator.userAgent)) {
            return false;
        }
        return ((userLang === 'de' && pageLang !== 'de') || (userLang !== 'de' && pageLang === 'de'));
    }
    get navigatorLang() {
        if (navigator.language === 'de' || navigator.language.includes('de-')) {
            return 'de';
        }
        else {
            return navigator.language;
        }
    }
}
LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i2.LocalStorageService)); }, token: LanguageService, providedIn: "root" });
